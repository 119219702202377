import React, { useCallback, useEffect, useMemo, useState } from "react";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import DashboardLayout from "../LayoutContainer/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Card, Grid, Box, Button } from "@mui/material";
import FormItem from "../../components/FormItem";
import { axios, isEmpty } from "../../common";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import useMuiConfirm from "../../hooks/useMuiConfirm";

import FileDropzone from "../../components/FileDropzone";
import FileList from "../../components/FileList";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useAuth } from "../../AuthProvider";

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { user } = useAuth();

  const { id } = location?.state || {};

  const [uploadFiles, setUploadFiles] = useState([]);

  console.log({ uploadFiles });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      memo: "",
    },
  });

  const loadAnnounce = useCallback(
    async (paramId) => {
      try {
        const { status, data } = await axios.get(`announce/${paramId}`);

        if (status === 200) {
          setValue("title", data.data.title);
          setValue("memo", data.data.memo);

          setUploadFiles(data.data.files);
        }
      } catch (e) {}
    },
    [setValue]
  );

  useEffect(() => {
    if (id) {
      loadAnnounce(id);
    }
  }, [id, loadAnnounce]);

  const onSubmit = useMemo(
    () =>
      debounce(async (form) => {
        try {
          // 업로드 파일
          let formData = new FormData();

          if (id) {
            formData.append("id", id);
          }
          formData.append("writerCusId", user?.id);
          formData.append("title", form.title);
          formData.append("memo", form.memo);

          const newUploadFile = uploadFiles.filter((f) => !f.isUploaded);
          if (!isEmpty(newUploadFile)) {
            for (let file of newUploadFile) {
              formData.append("files", file.file);
            }
          }

          //console.log({ newUploadFile });

          const { status } = await axios[id ? "patch" : "post"](
            "announce",
            formData,
            // {
            //   ...(id ? { id: id } : {}),
            //   title: form.title,
            //   memo: form.memo,
            //   formData,
            // },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (status === 200) {
            alert(`공지사항 ${id ? "수정" : "등록"} 성공`);
            setTimeout(() => {
              navigate("/certify/announces");
            }, 10);
          }
        } catch (e) {}
      }, 300),
    [id, navigate, uploadFiles, user]
  );

  const handleCancel = useMemo(
    () =>
      debounce(() => {
        navigate(-1);
      }, 300),
    [navigate]
  );

  const uploadFiledelete = useCallback(async (fileId) => {
    try {
      const { status } = await axios.delete("announce/file", {
        params: { id: fileId },
      });

      if (status === 200) {
        setUploadFiles((prev) => prev.filter((f) => f.id === fileId));
      }
    } catch (e) {
      alert("파일 삭제에 실패하였습니다.");
    }
  }, []);

  const [deleteMessage, setDeleteMessage] = useState();
  const [ConfirmDeleteDialog, confirmDelete] = useMuiConfirm(
    "삭제 확인",
    `${deleteMessage}을 삭제할까요?`
  );

  const handleDelete = useMemo(
    () =>
      debounce(async (filename) => {
        const f = uploadFiles.find((f) => f.fileName === filename);

        if (f?.isUploaded) {
          // 업로된 파일 삭제 여부 체크
          setDeleteMessage(f?.fileName);
          const confirm = await confirmDelete();

          if (confirm) {
            uploadFiledelete(f?.id);
          }
        } else {
          setUploadFiles((prev) => prev.filter((f) => f.fileName !== filename));
        }
      }, 300),
    [uploadFiles, confirmDelete, uploadFiledelete]
  );

  // 개별 파일 다운로드
  const handleDownload = useMemo(
    () =>
      debounce(async (params) => {
        try {
          const { status, data } = await axios.get(
            `/announce/download/${params?.id}`,
            { responseType: "blob", timeout: 30000 }
          );

          if (status === 200) {
            saveAs(data, params?.fileName);
          }
        } catch (e) {}
      }, 300),
    []
  );

  // 전체파일 다운로드
  const handleDownloadAll = useMemo(
    () =>
      debounce(async () => {
        try {
          const possibleDownload = uploadFiles.filter((f) => f.isUploaded);

          if (isEmpty(possibleDownload)) {
            alert("다운로드 가능한 파일이 없습니다.");
            return;
          }

          const zip = new JSZip();

          for (const fileItem of possibleDownload) {
            const { status, data } = await axios.get(
              `/announce/download/${fileItem.id}`,
              {
                responseType: "blob",
                timeout: 30000,
              }
            );

            if (status === 200) {
              zip.file(fileItem.fileName, data);
            }

            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "download");
            });
          }
        } catch (e) {}
      }, 300),
    [uploadFiles]
  );

  return (
    <DashboardLayout>
      <ConfirmDeleteDialog />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              공지사항 {id ? "수정" : "등록"}
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={2} px={2}>
            <Grid
              container
              rowSpacing={4.5}
              columnSpacing={2.75}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: "[필수] 공지사항 제목을 입력하세요.",
                  }}
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="공지사항 제목"
                      isRequired
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <MDTypography variant="h6">공지 내용</MDTypography>
                <Controller
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MDInput
                      multiline
                      rows={10}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="공지 내용을 입력하세요."
                      fullWidth
                    />
                  )}
                  name="memo"
                />
              </Grid>

              <Grid item xs={12}>
                <FileDropzone
                  uploadFiles={uploadFiles}
                  setUploadFiles={setUploadFiles}
                />
              </Grid>
              {!isEmpty(uploadFiles) && (
                <Grid item xs={12} mt={-1}>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MDButton
                      variant="outlined"
                      size="small"
                      color="success"
                      startIcon={<FileDownloadOutlinedIcon />}
                      onClick={handleDownloadAll}
                    >
                      전체 다운로드
                    </MDButton>
                  </MDBox>
                  <MDBox sx={{ boxShadow: 2, p: 2, borderRadius: 3 }} mt={1}>
                    <FileList
                      uploadFiles={uploadFiles}
                      onDelete={handleDelete}
                      onDownload={handleDownload}
                    />
                  </MDBox>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: theme.typography.pxToRem(10),

                    "& button": {
                      height: "50px",
                      width: "200px",
                    },

                    "& button + button": {
                      marginLeft: theme.typography.pxToRem(10),
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CancelOutlinedIcon />}
                    onClick={handleCancel}
                    sx={{
                      color: "#222",
                    }}
                  >
                    취소
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {id ? "수정" : "등록"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;
