import React, { useEffect, useCallback, useState, useMemo } from "react";

import { Card, IconButton } from "@mui/material";

import { debounce } from "loadsh";

import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import RefreshIcon from "@mui/icons-material/Refresh";

import DataTable from "../components/DataTable";
import { axios, consts } from "../common";
import { useAuth } from "../AuthProvider";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const HEAD_CELLS = [
  { id: "rowNumber", label: "No", width: "80px", isMd: true },
  { id: "invOrder", label: "발생장비", width: "150px", isMd: true },
  {
    id: "alertCode",
    label: "경보 메시지",
    isMd: true,
    format: (value) => consts.ALARM_CODE.find((f) => f.value === value)?.label,
  },

  {
    id: "alertLogDate",
    label: "",
    isMd: true,
    format: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
];

const _ = () => {
  const [isPending, setIsPending] = useState(true);
  const [data, setData] = useState();
  const { t } = useTranslation();

  const { station } = useAuth();

  const loadAlarm = useCallback(async (paramId) => {
    try {
      const { status, data } = await axios.get(
        `/station/alarm?stationId=${paramId}`
      );
      if (status === 200) {
        setData({ ...data, items: data.data });

        setIsPending(false);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (station?.stationNo) {
      setIsPending(true);
      loadAlarm(station.stationNo);
    }
  }, [loadAlarm, station]);

  const handleRefresh = useMemo(
    () =>
      debounce(() => {
        setIsPending(true);

        loadAlarm(station?.stationNo);
      }, 500),
    [loadAlarm, station]
  );

  return (
    <DashboardLayout>
      {!station?.stationNo ? (
        <MDBox py={3} sx={{ textAlign: "center" }}>
          <MDTypography variant="h6" color="info" textGradient>
            {t("emptyMessage.emptyStation")}
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox py={3}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    {station?.stationName}
                  </MDTypography>
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {t("labels.alarmHelp")}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox color="text" px={2}>
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </MDBox>

                {/* <MDTypography variant="h6" gutterBottom>
              {station?.stationName}의 실시간 알람
            </MDTypography>
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton> */}
              </MDBox>

              <DataTable
                headCells={HEAD_CELLS}
                data={data}
                emptyMessage={
                  isPending ? t("lables.alarmPending") : t("labels.alarmEmpty")
                }
                orderAlign="asc"
                isLoading={isPending}
              />
            </Card>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default _;
