import React, { useState } from "react";
import { Menu, Fade, MenuItem } from "@mui/material";
import MDButton from "./MDButton";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useTranslation } from "react-i18next";

const _ = ({ value, fn, menuOptions }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MDButton
        variant="outlined"
        color="dark"
        onClick={handleOpen}
        endIcon={<MenuOutlinedIcon />}
      >
        {t(`table.${menuOptions?.title}`)}
      </MDButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuOptions?.menu.map((item) => (
          <MenuItem
            key={item.id}
            onClick={(e) => {
              e.stopPropagation();
              fn(value, item.url);
            }}
          >
            {t(`breadcrumbs.${item.label}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default _;
