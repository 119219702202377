import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { useLocation, useNavigate, Link } from "react-router-dom";
import DashboardLayout from "../LayoutContainer/DashboardLayout";
import { axios, consts } from "../../common";
import DataTable from "../../components/DataTable";
import Pagination from "../../components/Pagination";
import SearchField from "../../components/SearchField";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "loadsh";
import dayjs from "dayjs";

const HEAD_CELLS = [
  { id: "rowNumber", label: "No", width: "80px", isMd: true },
  { id: "cusName", label: "고객명", isMd: true, align: "left" },
  { id: "cusId", label: "ID", isMd: false, align: "left" },
  { id: "cusTel", label: "연락처", isMd: true },
  {
    id: "role",
    label: "권한",
    isMd: false,
    format: (value) => consts.ROLES.find((f) => f.name === value)?.label,
  },
  {
    id: "joinDate",
    label: "등록일",
    format: (value) => dayjs(value).format("YYYY-MM-DD"),
    isMd: true,
  },
];

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();

  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState();
  const [itemsPerPage, setItemsPerPage] = useState();
  const [activePage, setActivePage] = useState();
  const [keyword, setKeyword] = useState();

  const changePaginations = useCallback(
    (type, payload) => {
      if (!itemsPerPage) {
        return;
      }

      const params = new URLSearchParams();

      const i = type === "itemsPerPage" ? payload : itemsPerPage;
      params.append("items_per_page", `${i}`);

      const p = type === "activePage" ? payload : activePage;
      params.append("page", `${p}`);

      if (keyword) {
        params.append("keyword", keyword);
      }

      const qs = `?${params.toString()}`;
      if (location.search !== qs) {
        navigate(qs, { replace: !!location.search });
      }
    },
    [itemsPerPage, location, activePage, navigate, keyword]
  );

  const loadItems = useCallback(
    async (page, keyword = "") => {
      if (!page) return;

      try {
        const { status, data } = await axios.get("/certify/customer", {
          params: {
            page: page,
            pageSize: itemsPerPage,
            keyword: keyword,
          },
        });

        if (status === 200) {
          setData(data.data);
          setIsPending(false);
        }
      } catch (error) {
        setTimeout(() => {
          setIsPending(false);
        }, 1);
      }
    },
    [itemsPerPage]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setItemsPerPage(Number.parseInt(params.get("items_per_page") || "20", 10));
    setActivePage(Number.parseInt(params.get("page") || "1", 10));
    if (params.get("keyword")) {
      setKeyword(params.get("keyword"));
    } else {
      setKeyword(null);
    }
  }, [location]);

  useEffect(() => {
    setIsPending(true);
    loadItems(activePage, keyword);
  }, [loadItems, activePage, keyword]);

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (keyword) {
      setValue("keyword", keyword);
    }
  }, [keyword, setValue]);

  const onSubmit = useMemo(
    () =>
      debounce((form) => {
        console.log({ form });
        //loadItems(1, form.keyword);
        const params = new URLSearchParams();
        params.append("items_per_page", itemsPerPage);
        params.append("page", 1);
        if (form.keyword) {
          params.append("keyword", form.keyword);
        }
        setKeyword(form.keyword);
        const qs = `?${params.toString()}`;

        if (location.search !== qs) {
          navigate(qs, { replace: true });
        }
      }, 300),
    [itemsPerPage, location, navigate]
  );

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              고객 관리
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={2} px={2}>
            <Grid container rowSpacing={1} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <MDButton
                  component={Link}
                  to={"/certify/customers/create"}
                  variant="outlined"
                  size="large"
                  color={"info"}
                >
                  고객 등록
                </MDButton>
              </Grid>
              <Grid item xs={12} md={3} lg={6} />
              <Grid item xs={12} sm={12} md={5} lg={4}>
                <Controller
                  control={control}
                  name="keyword"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <SearchField
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      handleSearch={handleSubmit(onSubmit)}
                      sx={{ marginRight: theme.typography.pxToRem(2) }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <DataTable
                  headCells={HEAD_CELLS}
                  data={data}
                  isLoading={isPending}
                  columnId="cusNo"
                  onHref={(val) =>
                    navigate("/certify/customers/create", {
                      state: { id: val },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {data?.items && (
                  <Pagination
                    page={activePage}
                    count={
                      itemsPerPage
                        ? Math.ceil(data.totalItemCount / itemsPerPage)
                        : 0
                    }
                    onChange={(_, page) =>
                      changePaginations("activePage", page)
                    }
                  />
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;
