/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, useCallback } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

import { debounce } from "loadsh";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";

import { useMediaQuery, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import SelectField from "../../../components/SelectField";
import MDTypography from "../../../components/MDTypography";

// Material Dashboard 2 React example components
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from "@mui/icons-material/Email";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import { signout } from "../../../common";
import { useNavigate } from "react-router-dom";
import { langUtil } from "../../../utils/lang";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "./styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setLang,
} from "../../../context";

import { ReactComponent as USFlag } from "../../../assets/icons/us.svg";
import { ReactComponent as KRFlag } from "../../../assets/icons/ko.svg";
import { Typography } from "@mui/material";
import MyStationField from "../../elements/MyStationField";
import StationMappField from "../../elements/StationMappField";
import { useAuth } from "../../../AuthProvider";
import { axios, consts } from "../../../common";
import { stationUtil } from "../../../utils/station";

function DashboardNavbar({ absolute, light, isMini }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const { user, station, setStation } = useAuth();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
    lang,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  //console.log({ route });

  const navigate = useNavigate();

  // localemenu state
  const [localeMenu, setLocaleMenu] = useState(null);

  const handleLocaleMenuOpen = useCallback((ev) => {
    setLocaleMenu(ev.currentTarget);
  }, []);

  const handleLocaleMenuClose = useCallback(
    async (param) => {
      setLocaleMenu(null);
      await langUtil.setLang(param);
      setLang(dispatch, param);
    },
    [dispatch]
  );

  // profilemenu state
  const [profileMenu, setProfileMenu] = useState(null);
  const handleProfileMenuOpen = useCallback((ev) => {
    setProfileMenu(ev.currentTarget);
  }, []);
  const handleProfileMenuClose = useCallback(
    async (ev) => {
      setProfileMenu(null);

      // logout
      if (ev === "logout") {
        await signout();
        setTimeout(() => {
          navigate("/signin", { replace: true });
        }, 1);
      }
    },
    [navigate]
  );

  // const handleLocaleMenuOpen = useMemo(
  //   () =>
  //     debounce((ev) => {
  //       console.log({ ev });
  //       setLocaleMenu(ev.currentTarget);
  //     }, 300),
  //   []
  // );
  // console.log({ localeMenu });

  // const handleLocaleMenuClose = useMemo(
  //   () =>
  //     debounce(() => {
  //       setLocaleMenu(null);
  //     }, 300),
  //   []
  // );

  // const [anchorEl, setAnchorEl] = useState(null);

  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   console.log({ event });
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // console.log({ anchorEl });

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  //const handleFullScreen = () => setFullScreen(dispatch, true);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<KRFlag style={{ width: 30, height: 20 }} title="Korean" />}
      />

      {/* <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      /> */}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  //console.log({ route });

  // change station...
  const changeStation = useMemo(
    () =>
      debounce(async (paramId) => {
        try {
          if (!paramId) return;

          // localstorage에 저장
          stationUtil.setStationId(paramId);
          const { status, data } = await axios.get("station/info", {
            params: { stationId: paramId },
          });

          if (status === 200) {
            setStation(data.data);
          }
        } catch (e) {}
      }, 300),
    [setStation]
  );

  const [stationValue, setStationValue] = useState();
  useEffect(() => {
    if (station?.stationNo) {
      setStationValue({
        stationNo: station.stationNo,
        title: `${station.stationName}(${station.stationNo})`,
      });
    }
  }, [station]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            //title={"a"}
            route={route}
            light={light}
          />
        </MDBox>
        {!isMini && (
          // <MDBox sx={{ display: "flex", flexGrow: 1, flex: 1 }}>
          <MDBox
            sx={{
              display: "flex",
              flex: 1,
              width: "100%",
              //marginLeft: "6px",

              marginLeft: theme.typography.pxToRem(10),
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                {user?.role === "Authority" ? (
                  <MDBox sx={{ maxWidth: "350px" }}>
                    <StationMappField
                      value={stationValue}
                      // value={
                      //   station?.stationNo
                      //     ? {
                      //         stationNo: station?.stationNo,
                      //         title: `${station?.stationName}(${station?.stationNo})`,
                      //       }
                      //     : null
                      // }
                      onChange={(e) => {
                        if (e?.stationNo) {
                          changeStation(e.stationNo);
                        }
                      }}
                    />
                  </MDBox>
                ) : (
                  <MDBox sx={{ maxWidth: "350px" }}>
                    {!route.includes("dashboard") && (
                      <MyStationField
                        cusId={user?.id}
                        value={stationValue}
                        onChange={(e) => {
                          if (e?.stationId) {
                            changeStation(e.stationId);
                          }
                        }}
                      />
                    )}
                  </MDBox>
                )}
              </Grid>
            </Grid>
          </MDBox>
        )}
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              {/* <Flags.US /> */}
              {/* 
              <MDBox sx={{}}>
                <MDBox sx={{ width: "500px" }}>
                  <SelectField
                    placeholder="발전소"    
                    valueOptions={[{ value: "all", label: "전체" }]}
                  />
                </MDBox>
              </MDBox> */}

              {/* 공지사항 버튼 */}
              {/* <IconButton sx={navbarIconButton} size="small" disableRipple>
                <Badge badgeContent={100} color="secondary">
                  <EmailIcon style={{ width: 50, height: 30 }} />
                </Badge>
              </IconButton> */}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="locale menu"
                aria-haspopup="true"
                variant="contained"
                onClick={(e) => handleLocaleMenuOpen(e)}
              >
                {lang === "ko" ? <KRFlag /> : <USFlag />}
              </IconButton>
              {!!localeMenu && (
                <Menu
                  id="basic-menu"
                  anchorEl={localeMenu}
                  open={!!localeMenu}
                  onClose={() => handleLocaleMenuClose()}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => handleLocaleMenuClose("ko")}
                    sx={{
                      "& svg": {
                        marginRight: "5px",
                      },
                    }}
                  >
                    <KRFlag style={{ width: 30, height: 20 }} />
                    <Typography variant="h6">Korean</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLocaleMenuClose("en")}
                    sx={{
                      "& svg": {
                        marginRight: "5px",
                      },
                    }}
                  >
                    <USFlag style={{ width: 30, height: 20 }} />
                    <Typography variant="h6">English</Typography>
                  </MenuItem>
                </Menu>
              )}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                {miniSidenav ? (
                  <MenuOpenIcon style={{ width: 50, height: 30 }} />
                ) : (
                  <MenuIcon style={{ width: 50, height: 30 }} />
                )}
              </IconButton>

              <IconButton
                sx={navbarIconButton}
                size="small"
                disableRipple
                onClick={() => {
                  //handleFullScreen();
                  //handleFullScreen();
                  //setFullScreen(dispatch, true);
                  toggleFullScreen();
                }}
              >
                <FullscreenIcon style={{ width: 50, height: 30 }} />
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="profile menu"
                aria-haspopup="true"
                variant="contained"
                onClick={(e) => handleProfileMenuOpen(e)}
              >
                <AccountCircleIcon style={{ width: 50, height: 30 }} />
              </IconButton>

              {!!profileMenu && (
                <Menu
                  id="basic-menu"
                  anchorEl={profileMenu}
                  open={!!profileMenu}
                  onClose={() => handleProfileMenuClose()}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem>
                    <MDTypography variant="h6">
                      {user?.cusName || ""}
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      sx={{ marginLeft: theme.typography.pxToRem(5) }}
                    >
                      (
                      {user?.role
                        ? consts.ROLES.find((f) => f.name === user.role)?.label
                        : ""}
                      )
                    </MDTypography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleProfileMenuClose("edit")}
                    sx={{
                      "& svg": {
                        marginRight: "5px",
                      },
                    }}
                  >
                    <ManageAccountsIcon style={{ width: 50, height: 30 }} />
                    <MDTypography variant="h6">Edit Profile</MDTypography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleProfileMenuClose("logout")}
                    sx={{
                      "& svg": {
                        marginRight: "5px",
                      },
                    }}
                  >
                    <LogoutIcon style={{ width: 50, height: 30 }} />
                    <MDTypography variant="h6">Logout</MDTypography>
                  </MenuItem>
                </Menu>
              )}
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}></MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;

function toggleFullScreen() {
  var doc = window.document;
  var docEl = doc.documentElement;

  var requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  var cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}
