import React, { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";
import MDButton from "./MDButton";

import { useDropzone } from "react-dropzone";

// icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const DEFAULT_MB = 1048576; // 1mega byte

const _ = ({
  uploadFiles = [],
  setUploadFiles,
  limitFile = 10,
  limitFileSize = 10,
  acceptFileType = {
    "image/*": [".jpg", ".jpeg", ".png"],
    "application/pdf": [".pdf"],
  },
}) => {
  const theme = useTheme();

  const onDropRejected = useCallback(
    (rejected) => {
      console.log({ rejected });

      // foreach로변경 로직 추가

      //"file-too-large"
      const isLargeFile = rejected.findIndex(
        (f) => f.errors[0].code === "file-too-large"
      );

      const isMaxFiles = rejected.findIndex(
        (f) => f.errors[0].code === "too-many-files"
      );

      if (isLargeFile !== -1) {
        alert("첨부가능한 파일 용량을 초과하였습니다.");
        return;
      }

      if (isMaxFiles !== -1) {
        alert(`첨부파일은 최대${limitFile}개까지 첨부 가능합니다.`);
        //return;
      }
    },
    [limitFile]
  );

  const onDrop = useCallback(
    async (acceptFiles, rejectedFiles) => {
      console.log({ rejectedFiles });

      // 중복 파일명 갯수 체크
      const checkFile = await isExistFiles(acceptFiles, uploadFiles);

      // 새로추가파일 파일 개수(중복파일을 뺀 갯수)
      const newFileCount = acceptFiles.length - checkFile;
      if (newFileCount > 0) {
        if (uploadFiles.length + newFileCount <= limitFile) {
          let newFiles = [];
          acceptFiles.forEach((file) => {
            if (uploadFiles.findIndex((f) => f.fileName === file.name) === -1) {
              newFiles.push({
                file,
                fileName: file.name,
                fileType: file.type,
                fileSize: file.size,
              });
            }
          });
          setUploadFiles((prev) => [...prev, ...newFiles]);
        } else {
          alert(`첨부파일은 최대 ${limitFile}개까지 추가할 수 있습니다.1`);
        }
      }
    },
    [limitFile, setUploadFiles, uploadFiles]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: acceptFileType,
    noKeyboard: true,
    maxSize: DEFAULT_MB * limitFileSize,
    maxFiles: limitFile,
    onDrop,
    onDropRejected,
  });

  return (
    <MDBox
      sx={{
        // borderColor: "#efefef",
        // borderWidth: 1,
        // borderRadius: 3,
        boxShadow: 2,
        p: 2,
        borderRadius: 3,
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontWeight: 700,
          marginTop: theme.typography.pxToRem(10),

          //   "& svg": {
          //     fontSize: 20,
          //   },

          //   "& button": {
          //     marginLeft: theme.typography.pxToRem(30),
          //   },
        }}
      >
        <MDBox sx={{ display: "flex", alignItems: "center" }}>
          <AttachFileOutlinedIcon />
          <MDTypography variant="h6">첨부파일</MDTypography>
        </MDBox>
        <MDButton
          variant="outlined"
          size="small"
          color={"info"}
          onClick={() => open()}
        >
          파일 첨부
        </MDButton>
        {/* <Button
          startIcon={<AttachFileOutlinedIcon />}
          variant="outlined"
          size="small"
          onClick={() => {
            open();
          }}
        >
          파일 첨부
        </Button> */}
      </MDBox>
      <MDBox {...getRootProps()} mt={3}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <MDTypography
            variant="body2"
            sx={{ display: "flex", textAlign: "center", flexWrap: "wrap" }}
          >
            업로드할 파일을 여기에 놓으세요.
          </MDTypography>
        ) : (
          <MDBox>
            <MDTypography variant="body2" sx={{ textAlign: "center" }}>
              업로드할 파일을 끌어다 놓거나, 파일첨부 이용하여 파일을
              선택하세요.
            </MDTypography>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: theme.typography.pxToRem(8),
                //gap: theme.typography.pxToRem(1),
                justifyContent: "center",
                "& svg": {
                  marginTop: theme.typography.pxToRem(3),
                  marginRight: theme.typography.pxToRem(1),
                },

                "& .acceptfile": {
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              }}
            >
              <InfoOutlinedIcon style={{ fontSize: 16 }} />
              <MDBox className="acceptfile">
                <MDTypography variant="body2">첨부파일은 </MDTypography>
                <MDTypography
                  variant="body2"
                  sx={{ color: "red", fontWeight: 700 }}
                >
                  {Math.floor(limitFileSize)}MB 이하(JPG, PNG, PDF) 파일
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="red"
                  fontWeight={700}
                  ml={theme.typography.pxToRem(2)}
                >
                  {limitFile}
                </MDTypography>
                <MDTypography variant="body2">
                  개 까지 등록할 수 있습니다.
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default _;

async function isExistFiles(newFiles, uploads) {
  let existCount = 0;

  for (const file of newFiles) {
    const check = uploads.findIndex((f) => f.fileName === file.name);

    if (check !== -1) {
      existCount += 1;
    }
  }
  return existCount;
}
