import React from "react";
import { Paper, Divider } from "@mui/material";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";
import MDBadge from "./MDBadge";
import dayjs from "dayjs";
import { regex, numberFixedFormat, formatWatt, consts } from "../common";

import { useTranslation } from "react-i18next";

const _ = ({
  title,
  invCapacity,
  invPower,
  todayTotal,
  co2Reduction,
  lastedLogDate,
  statusCode,
  isLoading = true,
  monthlySum = 0,
}) => {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        minWidth: "300px",
        width: "100%",
        borderRadius: 1,
      }}
    >
      <MDBox p={2}>
        <MDBox
          display="flex"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <MDBox
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            sx={{
              width: "100%",
              marginRight: "15px",
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
          >
            <MDTypography
              variant="body1"
              color="white"
              sx={{ textAlign: "center" }}
            >
              {title}
            </MDTypography>
          </MDBox>

          <MDBox
            sx={{
              textAlign: "center",
              minWidth: "80px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="caption" sx={{ textAlign: "center" }}>
              {t("station.installCapacity")}(kWp)
            </MDTypography>
            <MDTypography variant="h6">
              {String(invCapacity).replace(regex.CURRENCY, ",")}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MDTypography variant="button">
            {lastedLogDate
              ? dayjs(lastedLogDate).format("YYYY-MM-DD HH:mm:ss")
              : t("station.noreceive")}
          </MDTypography>
          <MDBadge
            color={
              isLoading
                ? "secondary"
                : consts.STATUS_CODE.find((f) => f.code === statusCode)?.color
            }
            variant="gradient"
            size="md"
            badgeContent={
              isLoading
                ? t("station.receiving")
                : consts.STATUS_CODE.find((f) => f.code === statusCode)?.text
            }
            container
          />
        </MDBox>
        <Divider />
        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <MDBox sx={{ textAlign: "center" }}>
            <MDTypography variant="h6" mb={-1} color="success">
              {`${formatWatt(invPower || 0).v} ${formatWatt(invPower || 0).f}`}
            </MDTypography>
            <MDTypography variant="caption">
              {t("dashboard.currentPower")}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ textAlign: "center" }}>
            <MDTypography variant="h6" mb={-1} color="warning">
              {`${formatWatt(todayTotal || 0).v} ${
                formatWatt(todayTotal || 0).f
              }h`}
            </MDTypography>
            <MDTypography variant="caption">
              {t("dashboard.currentEnergy")}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ textAlign: "center" }}>
            <MDTypography variant="h6" mb={-1} color="info">
              {`${formatWatt(monthlySum || 0).v} ${
                formatWatt(monthlySum || 0).f
              }h`}
              {/* {`${numberFixedFormat(monthlySum, 3)} kWH`} */}
            </MDTypography>
            <MDTypography variant="caption">
              {t("dashboard.monthlyEnergy")}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Paper>
  );
};

export default _;
