const ACCESS_KEY = "_t4gd-*-";
const REFRESH_KEY = "_dig_4u_";

export const auth = {
  isAuthenticated,
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  clearToken,
  isRemember,
};

function isAuthenticated() {
  return !!getToken();
}

function isRemember() {
  return !!localStorage.getItem(ACCESS_KEY);
}

function getToken() {
  return sessionStorage.getItem(ACCESS_KEY) || localStorage.getItem(ACCESS_KEY);
}

function setToken({ token, rememberMe = false }) {
  if (rememberMe) {
    localStorage.setItem(ACCESS_KEY, token);
  } else {
    sessionStorage.setItem(ACCESS_KEY, token);
  }
}

function getRefreshToken() {
  return (
    sessionStorage.getItem(REFRESH_KEY) || localStorage.getItem(REFRESH_KEY)
  );
}

function setRefreshToken({ token, rememberMe = false }) {
  if (rememberMe) {
    localStorage.setItem(REFRESH_KEY, token);
  } else {
    sessionStorage.setItem(REFRESH_KEY, token);
  }
}

function clearToken() {
  localStorage.removeItem(ACCESS_KEY);
  sessionStorage.removeItem(ACCESS_KEY);

  localStorage.removeItem(REFRESH_KEY);
  sessionStorage.removeItem(REFRESH_KEY);
}
