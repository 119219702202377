import { useState, useCallback, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme";
import useConstructor from "./hooks/useConstructor";

import Sidenav from "./screens/Sidenav";

import routes from "./routes";
import NotFound from "./screens/NotFound";

import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";
import { useAuth } from "./AuthProvider";
//import { auth } from "./utils/auth";
import { langUtil } from "./utils/lang";
import { setLang } from "./context";

import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";
import brandLogo from "./assets/images/logo.png";
import { axios } from "./common";
import { stationUtil } from "./utils/station";

import i18n from "./locales/i18n";

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    lang,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  const { setStation } = useAuth();

  //console.log({ lang });

  // const loadAccount = useCallback(async () => {
  //   try {
  //     const { status, data } = await axios.get("/accounts");

  //     if (status === 200) {
  //       setUser(data);
  //     }
  //   } catch (e) {}
  // }, [setUser]);

  const loadStationInfo = useCallback(
    async (stationId) => {
      try {
        const { status, data } = await axios.get("station/info", {
          params: { stationId },
        });

        if (status === 200) {
          setStation(data.data);
        }
      } catch (e) {}
    },
    [setStation]
  );

  useConstructor(() => {
    // if (auth.isAuthenticated()) {
    //   console.log("______________________");
    //   loadAccount();
    // }

    const id = stationUtil.getStationId();
    // console.log(
    //   "__________________" + id + "___________________________________"
    // );
    if (id) {
      loadStationInfo(id);
    }
  });

  //console.log({ pathname });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });

  // 최초 로딩시 언어 값 가져오기 저장된 값이 없으면 한국어 기본값
  useEffect(() => {
    (async function checkLang() {
      const loadLang = await langUtil.getLang();

      if (loadLang) {
        //i18n.changeLanguage(loadLang);
        setLang(dispatch, loadLang);
      }
    })();
  }, [dispatch]);

  // 언어 변경 인식시
  useEffect(() => {
    (async function changeLang() {
      if (lang) {
        i18n.changeLanguage(lang);
      }
    })();
  }, [lang]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {pathname !== "/signin" && pathname !== "/" && (
        <Sidenav
          color={sidenavColor}
          // brand={
          //   (transparentSidenav && !darkMode) || whiteSidenav
          //     ? brandDark
          //     : brandWhite
          // }
          brand={brandLogo}
          brandName="Blue"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}

      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;

// import React from "react";
// import { BrowserRouter } from "react-router-dom";
// import RouteContainer from "./routes";

// const App = () => {
//   return (
//     <BrowserRouter>
//       <RouteContainer />
//     </BrowserRouter>
//   );
// };

// export default App;

// import logo from "./logo.svg";
// import "./App.css";

// function App() {
//   //https://reactrouter.com/en/main/routers/picking-a-router
//   // 어떤 라우트를 쓸까나??
//   // https://codesandbox.io/s/gvt9rs?file=/App.tsx:384-409
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
