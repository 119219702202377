import React, { forwardRef } from "react";
import MDBox from "./MDBox";
import MDInput from "./MDInput";
import MDTypography from "./MDTypography";

const _ = forwardRef(
  ({ label, isRequired = false, fullWidth = true, ...rest }, ref) => {
    return (
      <MDBox>
        <MDBox sx={{ display: "flex" }}>
          <MDTypography variant="h6">{label}</MDTypography>
          {isRequired && (
            <MDTypography
              variant="h6"
              sx={{ color: "#ef3823", marginLeft: "4px" }}
            >
              *
            </MDTypography>
          )}
        </MDBox>
        <MDInput ref={ref} fullWidth={fullWidth} {...rest} />
      </MDBox>
    );
  }
);

export default _;
