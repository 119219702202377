import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import { StyledEngineProvider } from "@mui/joy/styles";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./AuthProvider";
import { BrowserRouter } from "react-router-dom";
import { MaterialUIControllerProvider } from "./context";
import "./locales/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </AuthProvider>
    </BrowserRouter>
    {/* <AuthProvider>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </AuthProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
