import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";

// time 위해서 필수 추가
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import configs from "./config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const _ = ({ configOptions, title, data }) => {
  const options = configs(configOptions, title);

  return <Line options={options} data={data} />;
};

export default _;
