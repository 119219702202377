import React from "react";
import { Tooltip, IconButton, useMediaQuery } from "@mui/material";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";
import { useTheme } from "@mui/material/styles";
import { extractFileName, shortenWords } from "../common";

// icons
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
//import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";

const _ = ({ uploadFiles = [], onDelete, onDownload }) => {
  const theme = useTheme();
  //const isMd = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <MDBox
      component="ul"
      sx={{
        display: "grid",
        //gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
        grap: "5px 20px",
        listStyle: "none",

        // [theme.breakpoints.up("lg")]: {
        //   gridTemplateColumns: "1fr 1fr 1fr ",
        // },

        // [theme.breakpoints.up("md")]: {
        //   gridTemplateColumns: "1fr 1fr 1fr",
        // },

        // [theme.breakpoints.down("md")]: {
        //   gridTemplateColumns: "1fr 1fr",
        // },

        // [theme.breakpoints.down("sm")]: {
        //   gridTemplateColumns: "1fr",
        // },
      }}
    >
      {uploadFiles.map((file) => (
        <MDBox
          key={file.fileName}
          component="li"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {file.contentType === "application/pdf" ? (
            <PictureAsPdfOutlinedIcon />
          ) : (
            <ImageOutlinedIcon />
          )}
          <MDTypography
            variant="subtitle2"
            ml={theme.typography.pxToRem(2)}
            mr={theme.typography.pxToRem(5)}
          >
            {shortenWords(extractFileName(file.fileName), 25)}
          </MDTypography>

          {onDelete && (
            <Tooltip title="파일삭제">
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  onDelete(file.fileName);
                }}
                sx={{ padding: theme.typography.pxToRem(3) }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          {file?.isUploaded && (
            <Tooltip title="다운로드">
              <IconButton
                aria-label="download"
                color="success"
                onClick={() => {
                  onDownload({ id: file.id, fileName: file.fileName });
                }}
                sx={{ padding: theme.typography.pxToRem(3) }}
              >
                <DownloadForOfflineOutlinedIcon />
              </IconButton>
            </Tooltip>
            // <MDButton
            //   variant="contained"
            //   size="small"
            //   color={"success"}
            //   sx={{ marginLeft: theme.typography.pxToRem(10) }}
            //   onClick={() => onDownload(file.id)}
            // >
            //   다운로드
            // </MDButton>
          )}
        </MDBox>
      ))}
    </MDBox>
  );
};

export default _;
