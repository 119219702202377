import React, { forwardRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ko";
import "dayjs/locale/en";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, FormHelperText } from "@mui/material";
import dayjs from "dayjs";

const _ = forwardRef(
  (
    {
      value,
      onChange,
      format = "YYYY-MM-DD",
      minDate = dayjs("2019-01-01"),
      maxDate = dayjs(),
      fullWidth = true,
      size = "small",
      error = null,
      helperText,
      locale = "ko",
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl fullWidth={fullWidth} size={size} error={!!error}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale}
          //dateFormats={{ monthShort: "M" }}
        >
          <DatePicker
            inputRef={ref}
            value={value}
            onChange={onChange}
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            defaultValue={dayjs()}
            sx={{ "& input": { textAlign: "center" } }}
            //view={"month"}
            //views={["year", "month"]}
            //views={["month"]}
            {...rest}
          />
        </LocalizationProvider>
        {!!error ? (
          <FormHelperText>{error}</FormHelperText>
        ) : (
          !!helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default _;
