import React from "react";
import { CircularProgress } from "@mui/material";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";

const _ = ({ loadingMessage }) => {
  return (
    <MDBox
      color="white"
      //bgColor="info"
      variant="gradient"
      borderRadius="lg"
      shadow="lg"
      opacity={1}
      p={2}
      display="flex"
      justifyContent="center"
    >
      <MDBox
        p={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="info" />
        {loadingMessage && (
          <MDTypography variant="button" color="info" p={3}>
            {loadingMessage}
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
};

export default _;
