import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Card, Grid, Chip } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { useLocation, useNavigate, Link } from "react-router-dom";
import DashboardLayout from "../LayoutContainer/DashboardLayout";
import { axios } from "../../common";
import DataTable from "../../components/DataTable";
import Pagination from "../../components/Pagination";
import SearchField from "../../components/SearchField";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "loadsh";

import { stationUtil } from "../../utils/station";

import { useAuth } from "../../AuthProvider";
import ShortMenu from "../../components/ShortMenu";
import { useTranslation } from "react-i18next";

const HEAD_CELLS = [
  { id: "rowNumber", label: "No", sx: { minWidth: "80px" }, isMd: true },
  { id: "stationName", label: "발전소명", isMd: true, align: "left" },
  { id: "stationAdd1", label: "주소", isMd: false, align: "left" },
  { id: "totalCapacity", label: "용량", format: (value) => value, isMd: true },
  {
    id: "totalCount",
    label: "개수",
    sx: { minWidth: "80px" },
    format: (value) => value,
    isMd: false,
  },
  // {
  //   id: "isActive",
  //   label: "상태",
  //   isMd: true,
  //   format: (value) => <Chip color="warning" label="정상" />,
  // },
  {
    id: "check",
    label: "점검",
    isMd: true,
    sx: { minWidth: "130px" },
    isButton: "changeStation",
    format: ({ value, fn }) => (
      <ShortMenu
        value={value}
        fn={fn}
        menuOptions={{
          title: "check",
          menu: [
            {
              id: 1,
              label: "station",
              url: "/station",
            },
            {
              id: 2,
              label: "inverter",
              url: "/inverter",
            },
            {
              id: 3,
              label: "alarm",
              url: "/alarm",
            },
            {
              id: 4,
              label: "history",
              url: "/history",
            },
            {
              id: 5,
              label: "daily",
              url: "/daily",
            },
            {
              id: 6,
              label: "monthly",
              url: "/monthly",
            },
            {
              id: 7,
              label: "yearly",
              url: "/yearly",
            },
          ],
        }}
      />
      // <MenuButton value={value} fn={fn} />
      // <MDButton
      //   variant="outlined"
      //   color="dark"
      //   onClick={(e) => {
      //     e.stopPropagation();
      //     fn(value, e);
      //   }}
      // >
      //   점검
      // </MDButton>
    ),
  },
];

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setStation } = useAuth();

  const theme = useTheme();

  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState();
  const [itemsPerPage, setItemsPerPage] = useState();
  const [activePage, setActivePage] = useState();
  const [keyword, setKeyword] = useState();

  const changePaginations = useCallback(
    (type, payload) => {
      if (!itemsPerPage) {
        return;
      }

      const params = new URLSearchParams();

      const i = type === "itemsPerPage" ? payload : itemsPerPage;
      params.append("items_per_page", `${i}`);

      const p = type === "activePage" ? payload : activePage;
      params.append("page", `${p}`);

      if (keyword) {
        params.append("keyword", keyword);
      }

      const qs = `?${params.toString()}`;
      if (location.search !== qs) {
        navigate(qs, { replace: !!location.search });
      }
    },
    [itemsPerPage, location, activePage, navigate, keyword]
  );

  const loadItems = useCallback(
    async (page, keyword = "") => {
      if (!page) return;

      //setActivePage(page);

      try {
        const { status, data } = await axios.get("/certify/station", {
          params: {
            page: page,
            pageSize: itemsPerPage,
            keyword: keyword,
          },
        });

        if (status === 200) {
          setData(data.data);
          setIsPending(false);
        }
      } catch (error) {
        setTimeout(() => {
          setIsPending(false);
        }, 1);
      }
    },
    [itemsPerPage]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setItemsPerPage(Number.parseInt(params.get("items_per_page") || "20", 10));
    setActivePage(Number.parseInt(params.get("page") || "1", 10));
    if (params.get("keyword")) {
      setKeyword(params.get("keyword"));
    } else {
      setKeyword(null);
    }
  }, [location]);

  useEffect(() => {
    setIsPending(true);
    loadItems(activePage, keyword);
  }, [loadItems, activePage, keyword]);

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (keyword) {
      setValue("keyword", keyword);
    }
  }, [keyword, setValue]);

  const onSubmit = useMemo(
    () =>
      debounce((form) => {
        //console.log({ form });
        //setActivePage(1);
        const params = new URLSearchParams();
        params.append("items_per_page", itemsPerPage);
        params.append("page", 1);
        if (form.keyword) {
          params.append("keyword", form.keyword);
        }
        setKeyword(form.keyword);
        const qs = `?${params.toString()}`;

        if (location.search !== qs) {
          navigate(qs, { replace: true });
        }
        //navigate(qs, { replace: true });
        //loadItems(1, form.keyword);
      }, 300),
    [itemsPerPage, navigate, location]
  );

  const changeStation = useMemo(
    () =>
      debounce(async (stationId, url) => {
        try {
          // localstorage에 저장
          stationUtil.setStationId(stationId);
          const { status, data } = await axios.get("station/info", {
            params: { stationId: stationId },
          });

          if (status === 200) {
            setStation(data.data);

            navigate(url);
          }
        } catch (e) {}
      }, 300),
    [navigate, setStation]
  );

  useEffect(() => {}, []);

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              발전소 관리
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={2} px={2}>
            <Grid container rowSpacing={1} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                {/* <MDButton
                  onClick={() => {
                    //console.log("발전소 등록");
                    navigate("/certify/stations/create");
                  }}
                  variant="contained"
                  color="primary"
                  sx={{
                    //background: "#719df0",
                    color: "#fff",
                  }}
                  fullWidth
                >
                  발전소 등록
                </MDButton> */}

                <MDButton
                  component={Link}
                  to={"/certify/stations/create"}
                  variant="outlined"
                  size="large"
                  color={"info"}
                >
                  발전소 등록
                </MDButton>
              </Grid>
              <Grid item xs={12} md={3} lg={6} />
              <Grid item xs={12} sm={12} md={5} lg={4}>
                <Controller
                  control={control}
                  name="keyword"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <SearchField
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      handleSearch={handleSubmit(onSubmit)}
                      sx={{ marginRight: theme.typography.pxToRem(2) }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <DataTable
                  headCells={HEAD_CELLS}
                  data={data}
                  isLoading={isPending}
                  columnId="stationNo"
                  onHref={(val) =>
                    navigate("/certify/stations/create", { state: { id: val } })
                  }
                  changeStation={changeStation}
                  orderAlign="asc"
                />
              </Grid>
              <Grid item xs={12}>
                {data?.items && (
                  <Pagination
                    page={activePage}
                    count={
                      itemsPerPage
                        ? Math.ceil(data.totalItemCount / itemsPerPage)
                        : 0
                    }
                    onChange={(_, page) =>
                      changePaginations("activePage", page)
                    }
                  />
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;
