import React, { useMemo } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isEmpty } from "../common";

import { useTranslation } from "react-i18next";

const _ = ({
  headCells = [],
  data,
  emptyMessage = "조회된 데이터가 없습니다.",
  columnId,
  onHref,
  isLoading = false,
  orderAlign = "desc",
  footer,
  ...rest
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const nested = headCells.filter((f) => f.nested);

  const nestedColspan = useMemo(() => {
    let resp = 0;

    if (nested.length > 0) {
      //resp = nested.reduce((sum, currvalue) )
      // nested되어 있는 배열의 갯수만 계산하기에 currValue는 불필요
      nested.forEach((item) => {
        let r = item.nested.reduce((sum, currValue) => {
          return sum + 1;
        });
        resp += r;
      });
    }

    return resp;
  }, [nested]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label="simple table">
        <TableHead sx={{ display: "contents" }}>
          <TableRow>
            {headCells.map((head) => {
              const cell = (
                <TableCell
                  key={head.id}
                  sx={{
                    color: "inherit",
                    textAlign: head?.align ? head.align : "center",
                    ///width: head?.width ? head.width : "auto",
                    //fontSize: "18px",
                    ...(head?.sx ? { ...head.sx } : {}),
                  }}
                  {...(head?.rowSpan && { rowSpan: head.rowSpan(isMd) })}
                  {...(head?.colSpan && { colSpan: head.colSpan(isMd) })}
                >
                  {head.id === "keyValue"
                    ? t(`table.${head.scale}`)
                    : t(`table.${head.id}`)}

                  {head?.scale && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      ({head.scale})
                    </Typography>
                  )}
                </TableCell>
              );

              return isMd ? cell : head.isMd && cell;
            })}
          </TableRow>
          {!isEmpty(nested) && (
            <TableRow>
              {nested.map((item) => {
                return item.nested.map((nestedItem) => {
                  const cell = (
                    <TableCell
                      key={nestedItem.id}
                      sx={{
                        color: "inherit",
                        textAlign: nestedItem?.align || "center",
                        ...(nestedItem.sx ? nestedItem.sx : {}),
                      }}
                    >
                      {t(`table.${nestedItem?.id}`)}
                      {nestedItem?.scale && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          ({nestedItem.scale})
                        </Typography>
                      )}
                    </TableCell>
                  );

                  return isMd ? cell : nestedItem.isMd && cell;
                });
              })}
            </TableRow>
          )}
        </TableHead>
        <TableBody sx={{ boxSizing: "border-box" }}>
          {isLoading ? (
            <TableRow>
              <TableCell
                colSpan={
                  isMd
                    ? headCells.length + nestedColspan
                    : headCells.filter((f) => !f?.isMd).length
                }
                sx={
                  {
                    // display: "flex",
                    // flex: 1,
                    // flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // /textAlign: "center",
                    // alignSelf: "center",
                  }
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="success" />
                  <Typography
                    variant="button"
                    sx={{ marginTop: theme.typography.pxToRem(10) }}
                  >
                    {emptyMessage}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : isEmpty(data?.items) ? (
            <TableRow>
              <TableCell
                colSpan={
                  isMd
                    ? headCells.length + nestedColspan
                    : headCells.filter((f) => f?.isMd).length
                }
                sx={{ textAlign: "center" }}
              >
                {emptyMessage}
              </TableCell>
            </TableRow>
          ) : (
            data.items.map((item, index) => {
              return (
                <TableRow
                  hover
                  key={index}
                  onClick={() => {
                    if (onHref) {
                      onHref(item[columnId]);
                    }
                  }}
                >
                  {headCells.map((column) => {
                    const nested = column.nested;

                    const rowNumber =
                      orderAlign === "desc"
                        ? data.totalItemCount -
                          ((data.page - 1) * data.pageSize + index)
                        : data?.pageSize
                        ? data.pageSize * (data.page - 1) + index + 1
                        : index + 1;

                    if (nested) {
                      return nested.map((nestedColumn, index) => {
                        const value = item[nestedColumn.id];

                        const cell = (
                          <TableCell
                            key={nestedColumn.id + index}
                            sx={{ textAlign: nestedColumn?.align || "center" }}
                          >
                            {nestedColumn?.format(value) || value}
                          </TableCell>
                        );

                        return isMd ? cell : nestedColumn.isMd && cell;
                      });
                    } else {
                      const value =
                        column.id === "rowNumber" ? rowNumber : item[column.id];

                      // const cell = (
                      //   <TableCell
                      //     key={column.id + index}
                      //     sx={{ textAlign: column?.align || "center" }}
                      //     onClick={(e) => {
                      //       if (column.onClick) {
                      //         e.stopPropagation();
                      //       }
                      //     }}
                      //   >
                      //     {column?.format
                      //       ? column.format(value)
                      //       : column?.onClick
                      //       ? column.onClick({
                      //           value: item[columnId],
                      //           navigate: navigate,
                      //         })
                      //       : value}
                      //   </TableCell>
                      // );

                      const cell = (
                        <TableCell
                          key={column.id + index}
                          sx={{
                            textAlign: column?.align || "center",
                            ...(column?.sx ? { ...column.sx } : {}),
                          }}
                          onClick={(e) => {
                            if (column?.isButton) {
                              e.stopPropagation();
                            }
                          }}
                        >
                          {column?.format
                            ? column.format(
                                column?.isButton
                                  ? {
                                      value: item[columnId],
                                      fn: window.eval(rest[column.isButton]),
                                    }
                                  : value
                              )
                            : value}
                        </TableCell>
                      );

                      return isMd ? cell : column.isMd && cell;
                    }
                  })}
                </TableRow>
              );
            })
          )}
          {footer || ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default _;
