import { useState, forwardRef } from "react";
import {
  Button,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDButton from "../components/MDButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useMuiConfirm = (
  title,
  message,
  confirmText = "예",
  cancelText = "아니오"
) => {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Dialog
      open={promise !== null}
      TransitionComponent={Transition}
      keepMounted
      //fullWidth={true}
      //maxWidth="lg"
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton variant="outlined" color="info" onClick={handleCancel}>
          {cancelText}
        </MDButton>
        <MDButton
          variant="contained"
          color="success"
          onClick={handleConfirm}
          autoFocus
        >
          {confirmText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default useMuiConfirm;
