import React, { forwardRef } from "react";
import {
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import MDInput from "./MDInput";

import { getFirstLetter } from "../common";

const _ = forwardRef(
  (
    {
      fullWidth = true,
      size = "small",
      name,
      value = "",
      optionValues,
      placeholder,
      onChange,
      //onBlur,
      error = null,
      helperText,
    },
    ref
  ) => {
    const loading = !!optionValues && optionValues.length === 0;

    const options = !!optionValues
      ? optionValues.map((option) => {
          const firstLetter = getFirstLetter(option.title[0].toUpperCase());
          return { firstLetter, ...option };
        })
      : [];

    return (
      <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        //size={size}
        error={!!error}
      >
        <Autocomplete
          //freeSolo //
          size={size}
          name={name}
          value={value}
          //inputValue={value}
          onChange={onChange}
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.title ?? ""}
          isOptionEqualToValue={(option, value) =>
            option?.title === value?.title
          }
          //You can use the `isOptionEqualToValue` prop to customize the equality test.
          //getOptionSelected={(option, value) => option.title === value.title}
          noOptionsText="검색 결과가 없습니다."
          loading={loading}
          renderInput={(params) => (
            <MDInput
              {...params}
              size={size}
              //value={value}
              inputRef={ref}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        {!!error ? (
          <FormHelperText>{error}</FormHelperText>
        ) : (
          !!helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default _;
