import React, { useCallback, useEffect, useMemo, useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import DashboardLayout from "../LayoutContainer/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Card, Grid, Box, Button, Checkbox } from "@mui/material";
import FormItem from "../../components/FormItem";
import SelectField from "../../components/SelectField";
import { axios, consts, regex } from "../../common";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";
import useMuiConfirm from "../../hooks/useMuiConfirm";
import FormLabel from "../../components/FormLabel";
import CustomerMapp from "./CustomerMapp";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { id } = location?.state || {};

  const [linkStations, setLinkStations] = useState([]);

  const handleLinkStation = useCallback((values) => {
    //setLinkStations(values);
    setLinkStations(values.map((item) => ({ ...item, isDelete: false })));
  }, []);

  const handleNewStation = useCallback((values) => {
    //const newValues = [];
    for (let el of values) {
      if (el?.isConnected) {
        setLinkStations((prev) =>
          prev.map((item) => ({
            ...item,
            // isConnected:
            //   item.stationNo === el.stationNo ? true : item.isConnected,
            isDelete: item.stationNo === el.stationNo ? false : item?.deleted,
          }))
        );
      } else {
        setLinkStations((prev) => [...prev, { ...el, isDelete: false }]);
      }
    }
    //setLinkStations((prev) => [...prev, ...values]);
  }, []);

  const handleDelStation = useCallback((values) => {
    for (let el of values) {
      //let f = linkStations.find((f) => f.stationNo === el.stationNo);

      if (el?.isConnected) {
        setLinkStations((prev) =>
          prev.map((item) => ({
            ...item,
            // isConnected:
            //   item.stationNo === el.stationNo ? false : item.stationNo,
            isDelete: item.stationNo === el.stationNo ? true : item?.isDelete,
          }))
        );
        // setLinkStations(
        //   linkStations.map((prev) => ({
        //     ...prev,
        //     isConnected:
        //       prev.stationNo === f.stationNo ? false : prev.isConnected,
        //   }))
        // );
      } else {
        setLinkStations((prev) =>
          prev.filter((item) => item.stationNo !== el.stationNo)
        );
        // setLinkStations(
        //   linkStations.filter((prev) => prev.stationNo !== f.stationNo)
        // );
      }
    }
    // values.forEach((element) => {
    //   let f = linkStations.find((f) => f.stationNo === element.stationNo);

    //   console.log({ f });

    //   if (f) {
    //     if (f.isConnected) {
    //       console.log("nofilter");
    //       setLinkStations(
    //         linkStations.map((prev) => ({
    //           ...prev,
    //           isConnected:
    //             prev.stationNo === f.stationNo ? false : prev.isConnected,
    //         }))
    //       );
    //     } else {
    //       console.log("filter");
    //       setLinkStations(
    //         linkStations.filter((prev) => prev.stationNo !== f.stationNo)
    //       );
    //     }
    //   }
    // });
  }, []);

  console.log({ linkStations });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cusName: "",
      cusId: "",
      cusIdApproved: false,
      cusPw: "",
      cusMail: "",
      cusTel: "",
      role: "",
    },
  });

  const loadCustomer = useCallback(
    async (paramId) => {
      try {
        const { status, data } = await axios.get(
          `/certify/customer/${paramId}`
        );

        if (status === 200) {
          setValue("cusName", data.data.cusName);
          setValue("cusId", data.data.cusId);
          setValue("cusMail", data.data.cusMail || "");
          setValue("cusTel", data.data.cusTel || "");
          setValue(
            "role",
            consts.ROLES.find((f) => f.name === data.data.role)?.value
          );
          setValue("cusIdApproved", true);
        }
      } catch (e) {}
    },
    [setValue]
  );

  useEffect(() => {
    if (id) {
      loadCustomer(id);
    }
  }, [id, loadCustomer]);

  const onSubmit = useMemo(
    () =>
      debounce(async (form) => {
        console.log({ form });
        if (!form.cusIdApproved) {
          setError("cusId", {
            message: "사용가능 아이디를 입력하고 중복체크를 완료하여 주세요.",
          });
          return;
        }

        //const stationIds
        const stationIds = [];
        for (let s of linkStations) {
          if ((s.isConnected && s.isDelete) || !s.isConnected) {
            stationIds.push({ id: s.stationNo, deleted: s.isDelete });
          }
        }

        try {
          const { status } = await axios[id ? "patch" : "post"](
            "/certify/customer",
            {
              ...(id ? { cusNo: id } : {}),
              cusName: form.cusName,
              cusId: form.cusId,
              cusPw: form.cusPw,
              cusMail: form.cusMail,
              cusTel: form.cusTel,
              role: form.role,
              stationIds: stationIds,
            }
          );

          if (status === 200) {
            alert(`사용자 ${id ? "수정" : "등록"} 성공`);
            setTimeout(() => {
              navigate("/certify/customers");
            }, 10);
          }
        } catch (e) {}
      }, 300),
    [id, navigate, setError, linkStations]
  );

  const handleCancel = useMemo(
    () =>
      debounce(() => {
        navigate(-1);
      }, 300),
    [navigate]
  );

  const [ConfirmResetDialog, confirmReset] = useMuiConfirm(
    "초기화",
    "패스워드를 초기화 할까요?"
  );

  const handleResetPassword = useMemo(
    () =>
      debounce(async () => {
        try {
          const confirm = await confirmReset();

          if (confirm) {
            const { status } = await axios.patch("/certify/reset-password", {
              cusNo: id,
            });

            if (status === 200) {
              alert("패스워드 초기화를 완료하였습니다.");
            }
          }
        } catch (e) {
          alert("패스워드 초기화에 실패하였습니다.");
        }
      }, 300),
    [confirmReset, id]
  );

  // const handleCusIdApproach = useCallback(async (cusId) => {

  // }, [])
  const [approved, setApproved] = useState();

  const handleUserIdApprove = useMemo(
    () =>
      debounce(async () => {
        try {
          const cusId = watch("cusId");
          const { status } = await axios.get("certify/checkid", {
            params: { cusId },
          });

          if (status === 200) {
            setValue("cusIdApproved", true);
            setError("cusId", { message: "" });
            setApproved("사용가능한 아이디 입니다.");
          }
        } catch (e) {
          setValue("cusIdApproved", false);
          setError("cusId", { message: "이미 사용중인 아이디 입니다." });
          setApproved(null);
        }
      }, 300),
    [setError, setValue, watch]
  );

  const [confirmMessage, setConfirmMessage] = useState();
  const [ConfirmDeleteDialog, confirmDelete] = useMuiConfirm(
    "삭제확인",
    confirmMessage
  );

  //console.log({ id });

  const handleDelete = useMemo(
    () =>
      debounce(async () => {
        try {
          setConfirmMessage("고객을 삭제하시겠습니까?");
          const confirm = await confirmDelete();
          if (confirm) {
            const { status } = await axios.delete("/certify/customer", {
              params: { cusNo: id },
            });

            if (status === 200) {
              alert("삭제 완료되었습니다.");
              setTimeout(() => {
                navigate("/certify/customers");
              }, 10);
            }
          }
        } catch (e) {}
      }, 300),
    [confirmDelete, id, navigate]
  );

  return (
    <DashboardLayout>
      <ConfirmResetDialog />
      <ConfirmDeleteDialog />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              고객 {id ? "수정" : "등록"}
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={2} px={2}>
            <Grid
              container
              rowSpacing={4.5}
              columnSpacing={2.75}
              sx={{ alignSelf: "baseline" }}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="cusName"
                  rules={{
                    required: "[필수] 고객명을 입력하여 주세요.",
                  }}
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="고객명"
                      isRequired
                      placeholder="고객명을 입력하세요."
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
                {errors?.cusName?.message && (
                  <FormLabel label={errors?.cusName.message} error />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel label="아이디" isRequired />
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& button": {
                      minWidth: "100px",
                      //width: "120px",
                      marginLeft: "5px",
                      color: "#328ced",
                      fontSize: "9pt",
                      height: "38px",
                    },
                  }}
                >
                  <Controller
                    control={control}
                    name="cusId"
                    rules={{
                      required: "[필수] 아이디를 입력하여 주세요.",
                    }}
                    render={({ field: { value, onChange, onBlur, ref } }) => (
                      <MDInput
                        placeholder="아이디"
                        fullWidth
                        size="small"
                        value={value}
                        onChange={(e) => {
                          console.log({ e });
                          setValue("cusIdApproved", false);
                          setError("cusId", {
                            message:
                              "사용가능 아이디를 입력하고 중복체크를 완료하여 주세요.",
                          });
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        ref={ref}
                      />
                    )}
                  />

                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    disabled={watch("cusIdApproved")}
                    onClick={handleUserIdApprove}
                  >
                    중복체크
                  </Button>
                </MDBox>
                {errors?.cusId?.message && (
                  <FormLabel label={errors?.cusId.message} error />
                )}
                {watch("cusIdApproved") === true && approved && (
                  <FormLabel label={approved} helper />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel label="패스워드" isRequired={!id} />

                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& button": {
                      minWidth: "100px",
                      //width: "120px",
                      marginLeft: "5px",
                      color: "#328ced",
                      fontSize: "9pt",
                      height: "38px",
                    },
                  }}
                >
                  <Controller
                    control={control}
                    name="cusPw"
                    rules={
                      !id
                        ? { required: "[필수] 패스워드를 입력하여 주세요." }
                        : {}
                    }
                    render={({ field: { value, onChange, onBlur, ref } }) => (
                      <MDInput
                        placeholder="패스워드"
                        fullWidth
                        size="small"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        type="password"
                      />
                    )}
                  />
                  {id && (
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={handleResetPassword}
                    >
                      초기화
                    </Button>
                  )}
                </MDBox>
                {errors?.cusPw?.message && (
                  <FormLabel label={errors?.cusPw.message} error />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="cusTel"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="연락처"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      placeholder="연락처를 입력하세요."
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="cusMail"
                  // rules={{
                  //   pattern: {
                  //     value: regex.EMAIL,
                  //     message: "유효한 이메일 주소를 입력하여 주세요.",
                  //   },
                  // }}
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="비고"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      placeholder="비고 사항을 입력하세요."
                    />
                  )}
                />
                {errors?.cusMail?.message && (
                  <FormLabel label={errors?.cusMail.message} error />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel label="권한 설정" isRequired />
                <Controller
                  control={control}
                  name="role"
                  rules={{
                    required: "[필수] 권한을 선택하세요.",
                  }}
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <SelectField
                      ref={ref}
                      value={value}
                      placeholder="권한"
                      onChange={onChange}
                      valueOptions={consts.ROLES}
                      //error={errors?.baseDevice ? errors.baseDevice.message : null}
                    />
                  )}
                />
                {errors?.role?.message && (
                  <FormLabel label={errors?.role.message} error />
                )}
              </Grid>

              {/* <Grid item xs={12} sm={12} md={4} lg={4}></Grid> */}
              <Grid item xs={12}>
                <FormLabel label="발전소 매칭" />

                <CustomerMapp
                  cusNo={id}
                  handleLinkStation={handleLinkStation}
                  handleNewStation={handleNewStation}
                  handleDelStation={handleDelStation}
                  // leftValues={leftValues}
                  // rightValues={rightValues}
                  // checked={checked}
                  // handleChecked={handleChecked}
                  // cusNo={id}
                  // checked={checked}
                  // setChecked={handleChecked}
                  //checked={checked}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: theme.typography.pxToRem(10),

                    "& button": {
                      height: "50px",
                      width: "200px",
                    },

                    "& button + button": {
                      marginLeft: theme.typography.pxToRem(10),
                    },
                  }}
                >
                  <MDButton
                    variant="outlined"
                    size="large"
                    startIcon={<CancelOutlinedIcon />}
                    onClick={handleCancel}
                    color="info"
                  >
                    취소
                  </MDButton>

                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {id ? "수정" : "등록"}
                  </Button>
                  {id && (
                    <MDButton
                      variant="contained"
                      size="large"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={handleDelete}
                      color="error"
                    >
                      삭제
                    </MDButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
