import React, {
  useCallback,
  useEffect,
  useMemo,
  //useRef,
  useState,
} from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import DashboardLayout from "../LayoutContainer/DashboardLayout";
import MDButton from "../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Card, Grid, Box, Button } from "@mui/material";
import FormItem from "../../components/FormItem";
import { axios } from "../../common";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useMuiConfirm from "../../hooks/useMuiConfirm";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
const placesLibrary = ["places"];

// const options = {
//   componentRestrictions: { country: "kr" },
//   fields: ["address_components", "geometry", "icon", "name"],
//   types: ["establishment"],
// };

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  //const autoCompleteRef = useRef();
  //const inputRef = useRef();

  // useEffect(() => {
  //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(
  //     inputRef.current,
  //     options
  //   );

  //   autoCompleteRef.current.addListener("place_changed", async function () {
  //     const place = await autoCompleteRef.current.getPlace();
  //     console.log({ place });
  //   });
  // }, []);

  const [searchResult, setSearchResult] = useState("Result: none");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCkY1rKNXNqsfhDh-ZFfiL30_ItzuXapic",
    libraries: placesLibrary,
  });

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  const { id } = location?.state || {};

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      stationName: "",
      zipcode: "",
      stationAdd1: "",
      stationAdd2: "",
      kmaNx: "",
      kmaNy: "",
      memo: "",
    },
  });

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const name = place.name;
      const status = place.business_status;
      const formattedAddress = place.formatted_address;
      console.log({ place });
      console.log(`Name: ${name}`);
      console.log(`Business Status: ${status}`);
      console.log(`Formatted Address: ${formattedAddress}`);
      setValue("stationAdd1", formattedAddress);

      setValue("zipcode", place?.address_components[6]?.long_name);
      setValue("kmaNx", place.geometry.location.lat());
      setValue("kmaNy", place.geometry.location.lng());
    } else {
      alert("Please enter text");
    }
  }

  const loadStation = useCallback(
    async (paramId) => {
      try {
        const { status, data } = await axios.get(`/certify/station/${paramId}`);

        if (status === 200) {
          setValue("stationName", data.data.stationName || "");
          setValue("zipcode", data.data.zipcode || "");
          setValue("stationAdd1", data.data.stationAdd1 || "");
          setValue("stationAdd2", data.data.stationAdd2 || "");
          setValue("kmaNx", data.data.kmaNx || "");
          setValue("kmaNy", data.data.kmaNy || "");
          setValue("memo", data.data.memo || "");
        }
      } catch (e) {}
    },
    [setValue]
  );

  useEffect(() => {
    if (id) {
      loadStation(id);
    }
  }, [id, loadStation]);

  const onSubmit = useMemo(
    () =>
      debounce(async (form) => {
        try {
          const { status } = await axios[id ? "patch" : "post"](
            "/certify/station",
            {
              ...(id ? { stationNo: id } : {}),
              stationName: form.stationName,
              zipcode: form.zipcode,
              stationAdd1: form.stationAdd1,
              stationAdd2: form.stationAdd2,
              kmaNx: form.kmaNx,
              kmaNy: form.kmaNy,
              memo: form.memo,
            }
          );

          if (status === 200) {
            alert(`발전소 ${id ? "수정" : "등록"} 성공`);
            setTimeout(() => {
              navigate("/certify/stations");
            }, 10);
          }
        } catch (e) {}
      }, 300),
    [id, navigate]
  );

  const handleCancel = useMemo(
    () =>
      debounce(() => {
        navigate(-1);
      }, 300),
    [navigate]
  );

  const [confirmMessage, setConfirmMessage] = useState();
  const [ConfirmDeleteDialog, confirmDelete] = useMuiConfirm(
    "삭제확인",
    confirmMessage
  );

  //console.log({ id });

  const handleDelete = useMemo(
    () =>
      debounce(async () => {
        try {
          setConfirmMessage("발전소를 삭제하시겠습니까?");
          const confirm = await confirmDelete();
          if (confirm) {
            const { status } = await axios.delete("/certify/station", {
              params: { stationNo: id },
            });

            if (status === 200) {
              alert("삭제 완료되었습니다.");
              setTimeout(() => {
                navigate("/certify/stations");
              }, 10);
            }
          }
        } catch (e) {
          alert("삭제 오류\n연결된 인버터를 모두 삭제해야 합니다.");
        }
      }, 300),
    [confirmDelete, id, navigate]
  );

  return (
    <DashboardLayout>
      <ConfirmDeleteDialog />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              발전소 {id ? "수정" : "등록"}
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={2} px={2}>
            <Grid
              container
              rowSpacing={4.5}
              columnSpacing={2.75}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="stationName"
                  rules={{
                    required: "[필수] 발전소 이름을 입력하여 주세요.",
                  }}
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="발전소 이름"
                      isRequired
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      placeholder="발전소 이름"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Controller
                  control={control}
                  name="kmaNx"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="NX"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      inputProps={{ readOnly: true }}
                      placeholder="위도 값"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Controller
                  control={control}
                  name="kmaNy"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="NY"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      inputProps={{ readOnly: true }}
                      placeholder="경도 값"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                    "& span": {
                      marginLeft: "5px",
                    },
                  }}
                >
                  <MDTypography variant="h6">주소</MDTypography>
                  <MDTypography variant="caption">
                    (주소검색 항목 입력시 우편번호, 위도값, 경도값이 자동
                    입력됩니다.)
                  </MDTypography>
                </MDBox>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      control={control}
                      name="zipcode"
                      render={({ field: { value, onChange, onBlur, ref } }) => (
                        <MDInput
                          placeholder="우편번호"
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          inputProps={{ readOnly: true }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    {isLoaded && (
                      <Controller
                        control={control}
                        name="stationAdd1"
                        render={({
                          field: { value, onChange, onBlur, ref },
                        }) => (
                          <Autocomplete
                            onPlaceChanged={onPlaceChanged}
                            onLoad={onLoad}
                          >
                            <MDInput
                              fullWidth
                              size="small"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              ref={ref}
                              placeholder="주소 검색"
                            />
                          </Autocomplete>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Controller
                      control={control}
                      name="stationAdd2"
                      render={({ field: { value, onChange, onBlur, ref } }) => (
                        <MDInput
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="상세주소"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item xs={12}>
                <MDTypography variant="h6">주소</MDTypography>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <MDBox
                      sx={{
                        display: "flex",
                        "& button": {
                          minWidth: "130px",
                          //width: "120px",
                          marginLeft: "5px",
                          color: "#328ced",
                          fontSize: "9pt",
                          //p: 0,
                        },
                      }}
                    >
                      <Controller
                        control={control}
                        name="zipcode"
                        render={({
                          field: { value, onChange, onBlur, ref },
                        }) => (
                          <MDInput
                            placeholder="우편번호"
                            fullWidth
                            size="small"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                          />
                        )}
                      />

                      <Button variant="outlined" size="small" color="secondary">
                        주소검색
                      </Button>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {isLoaded && (
                      <Controller
                        control={control}
                        name="stationAdd1"
                        render={({
                          field: { value, onChange, onBlur, ref },
                        }) => (
                          <Autocomplete
                            onPlaceChanged={onPlaceChanged}
                            onLoad={onLoad}
                          >
                            <MDInput
                              fullWidth
                              size="small"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              ref={ref}
                              placeholder="주소 검색"
                            />
                          </Autocomplete>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      control={control}
                      name="stationAdd2"
                      render={({ field: { value, onChange, onBlur, ref } }) => (
                        <MDInput
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="상세주소"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <MDTypography variant="h6">참고사항</MDTypography>
                <Controller
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MDInput
                      multiline
                      rows={10}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="참고사항을 입력하세요."
                      fullWidth
                    />
                  )}
                  name="memo"
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: theme.typography.pxToRem(10),

                    "& button": {
                      height: "50px",
                      width: "200px",
                    },

                    "& button + button": {
                      marginLeft: theme.typography.pxToRem(10),
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CancelOutlinedIcon />}
                    onClick={handleCancel}
                    sx={{
                      color: "#222",
                    }}
                  >
                    취소
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {id ? "수정" : "등록"}
                  </Button>
                  {id && (
                    <MDButton
                      variant="contained"
                      size="large"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={handleDelete}
                      color="error"
                    >
                      삭제
                    </MDButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;
