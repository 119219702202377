import React, { useEffect, useCallback, useState, useRef } from "react";

import { Grid, Card, useMediaQuery, IconButton } from "@mui/material";

import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";
import dayjs from "dayjs";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import DataTable from "../components/DataTable";
import { useTheme } from "@mui/material/styles";
import LineChart from "../components/Charts/LineChart";

import DatePickerField from "../components/DatePickerField";
import SelectField from "../components/SelectField";
import Pagination from "../components/Pagination";

import { numberFixedFormat, axios, consts, getColor } from "../common";
import queryString from "query-string";

import RefreshIcon from "@mui/icons-material/Refresh";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

import { useTranslation } from "react-i18next";

import { useReactToPrint } from "react-to-print";

const HEAD_CELLS = [
  {
    id: "logDate",
    label: "계측시간",
    width: "180px",
    rowSpan: (isMd) => 2,
    format: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    isMd: true,
  },
  {
    id: "invOrder",
    label: "국번",
    width: "80px",
    rowSpan: (isMd) => 2,
    isMd: true,
  },

  {
    id: "input",
    label: "입력측",
    colSpan: (isMd) => (isMd ? 3 : 1),
    align: "center",
    nested: [
      {
        id: "solarVolt",
        label: "전압",
        align: "center",
        scale: "V",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
      {
        id: "solarCurrent",
        label: "전류",
        align: "center",
        scale: "A",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },

      {
        id: "solarPower",
        label: "전력",
        align: "center",
        scale: "kW",
        format: (value) => numberFixedFormat(value, 0),
        isMd: true,
      },
    ],
    isMd: true,
  },

  {
    id: "output",
    label: "출력측",
    colSpan: (isMd) => (isMd ? 3 : 1),
    align: "center",
    nested: [
      {
        id: "lineVoltRs",
        label: "전압",
        align: "center",
        scale: "V",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
      {
        id: "lineCurrR",
        label: "전류",
        align: "center",
        scale: "A",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },

      {
        id: "invPower",
        label: "전력",
        align: "center",
        scale: "kW",
        format: (value) => numberFixedFormat(value, 0),
        isMd: true,
      },
    ],
    isMd: true,
  },

  {
    id: "invPf",
    label: "역률",
    align: "center",
    rowSpan: (isMd) => 2,
    scale: "%",
    format: (value) => numberFixedFormat(value, 1),
    isMd: false,
  },
  {
    id: "lineFrequency",
    label: "주파수",
    align: "center",
    rowSpan: (is) => 2,
    scale: "Hz",
    format: (value) => numberFixedFormat(value, 1),
    isMd: false,
  },
  {
    id: "invTotal",
    label: "적산발전량",
    align: "center",
    rowSpan: (isMd) => 2,
    scale: "kWh",
    format: (value) => numberFixedFormat(value, 0),
    isMd: true,
  },
];

const _ = () => {
  const { station } = useAuth();

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isPending, setIsPending] = useState(true);
  const [selectDate, setSelectDate] = useState(dayjs());
  const [selectDevice, setSelectDevice] = useState("all");
  const [selectTarget, setSelectTarget] = useState("solarPower");
  const [itemsPerPage, setItemsPerPage] = useState();
  const [activePage, setActivePage] = useState();
  const [chartItem, setChartItem] = useState();
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();

  console.log({ selectDevice });

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    setItemsPerPage(Number.parseInt(parsed?.items_per_page || 20, 10));
    setActivePage(Number.parseInt(parsed?.page || 1), 10);
  }, [location]);

  const IdLabel = t("labels.orderNumber");

  const renderChart = useCallback(
    (chartData, selDev, selTarget) => {
      if (!chartData) {
        return;
      }

      let charts = [];
      //const Id = t("lables.orderNumber");

      if (selDev === "all") {
        station?.devices.forEach((item, index) => {
          var t = chartData
            .filter((f) => f.invOrder === item.label)
            .map((c) => ({
              x: dayjs(c.logDate).format("YYYY-MM-DD HH:mm:ss"),
              y: c[selTarget],
            }));

          let color = getColor(index);
          charts.push({
            //label: `${t("labels.orderNumber")} ${item.label}`,
            label: `${IdLabel} ${item.label}`,
            data: t,
            borderColor: color,
            borderWidth: 2,
            lineTension: 0.2,
            backgroundColor: color,
          });
        });
      } else {
        const invOrder = station?.devices.find(
          (f) => f.value === selDev
        )?.label;

        var t = chartData
          .filter((f) => f.invOrder === invOrder)
          .map((c) => ({
            x: dayjs(c.logDate).format("YYYY-MM-DD HH:mm:ss"),
            y: c[selTarget],
          }));

        let color = getColor(0);
        charts.push({
          label: `${IdLabel} ${invOrder}`,
          data: t,
          borderColor: color,
          borderWidth: 2,
          lineTension: 0.2,
          backgroundColor: color,
        });
      }

      setChartItem({ datasets: charts });
    },
    [IdLabel, station]
  );

  const loadTrend = useCallback(
    async (stationId, toDate, selDevice, page = 1) => {
      try {
        const { status, data } = await axios.get("station/trend", {
          params: {
            stationId,
            toDate: dayjs(toDate).format("YYYY-MM-DD"),
            toDevice: selDevice === "all" ? 0 : selDevice,
            page,
            pageSize: itemsPerPage,
          },
        });

        if (status === 200) {
          setData(data.data.table);

          setChartData(data.data.chart);

          //renderChart(data.data.chart, selectDevice, selectTarget);
          setIsPending(false);
        }
      } catch (e) {}
    },
    [itemsPerPage]
  );

  useEffect(() => {
    if (chartData && selectDevice && selectTarget) {
      //console.log({ selectDevice, selectTarget });
      renderChart(chartData, selectDevice, selectTarget);
    }
  }, [chartData, renderChart, selectDevice, selectTarget]);

  useEffect(() => {
    if (station?.stationNo && selectDate && selectDevice && activePage) {
      loadTrend(station.stationNo, selectDate, selectDevice, activePage);
    }
  }, [loadTrend, station, selectDevice, selectDate, activePage]);

  const changePaginations = useCallback(
    (type, payload) => {
      if (!itemsPerPage) return;

      const params = new URLSearchParams();

      const i = type === "itemsPerPage" ? payload : itemsPerPage;
      params.append("items_per_page", `${i}`);

      const p = type === "activePage" ? payload : activePage;
      params.append("page", `${p}`);

      const qs = `?${params.toString()}`;
      if (location.search !== qs) {
        navigate(qs, { replace: !!location.search });
      }
    },
    [itemsPerPage, activePage, navigate, location]
  );

  const refPrint = useRef();

  const title = t("labels.inverterStatus");
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
    documentTitle: `${station?.stationName} ${title}`,
    pageStyle: `
    
    @media print {
      .no-print {
        display: none !important;
      }

      @page { size: landscape; }
    }
    `,
  });

  return (
    <DashboardLayout>
      {!station?.stationNo ? (
        <MDBox py={3} sx={{ textAlign: "center" }}>
          <MDTypography variant="h6" color="info" textGradient>
            {t("emptyMessage.emptyStation")}
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox py={3}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    {station?.stationName}
                  </MDTypography>
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {t("labels.inverterHelp")}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox color="text" px={2}>
                  <IconButton>
                    <RefreshIcon />
                  </IconButton>
                </MDBox>
              </MDBox>
              <MDBox sx={{ display: "flex", justifyContent: "flex-end" }} m={3}>
                <Grid container>
                  {isMd && <Grid item md={4} lg={6}></Grid>}
                  <Grid item xs={12} md={8} lg={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <DatePickerField
                          value={selectDate}
                          onChange={(e) => {
                            changePaginations("activePage", 1);
                            setSelectDate(dayjs(e));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <SelectField
                          value={selectDevice}
                          onChange={(e) => {
                            setSelectDevice(e.target.value);
                            setActivePage(1);
                          }}
                          valueOptions={[
                            { value: "all", label: t("labels.all") },
                            ...(station?.devices || []),
                          ]}
                          isClearButton={false}
                          placeholder={t("lables.orderNumber")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <SelectField
                          value={selectTarget}
                          onChange={(e) => {
                            setSelectTarget(e.target.value);
                          }}
                          valueOptions={consts.IVT_TARGET}
                          isClearButton={false}
                          placeholder={t("labels.target")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="medium"
                          fullWidth={true}
                          startIcon={<LocalPrintshopOutlinedIcon />}
                          onClick={handlePrint}
                        >
                          {t("labels.buttonPrint")}
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox ref={refPrint}>
                <MDBox
                  p={3}
                  sx={{ "& canvas": { minHeight: isMd ? "45vh" : "35vh" } }}
                >
                  {chartItem && (
                    <LineChart
                      configOptions={{
                        xTitle: t("labels.time"),
                        yTitle: consts.IVT_TARGET.find(
                          (f) => f.value === selectTarget
                        )?.x,
                        xOtherOptions: {
                          type: "time",
                          time: {
                            unit: "hour",
                            displayFormats: { hour: "HH" },
                          },
                          min: `${selectDate.format("YYYY-MM-DD")} 05:00:00`,
                          max: `${selectDate.format("YYYY-MM-DD")} 21:00:00`,
                        },
                      }}
                      title={`${selectDate.format("YYYY-MM-DD")}`}
                      data={chartItem}
                    />
                  )}
                </MDBox>
                <MDBox p={3}>
                  <DataTable
                    headCells={HEAD_CELLS}
                    data={data}
                    emptyMessage="발전상황을 불러올수 없습니다."
                  />
                  {data?.items && (
                    <Pagination
                      page={activePage}
                      count={
                        itemsPerPage
                          ? Math.ceil(data?.totalItemCount / itemsPerPage)
                          : 0
                      }
                      onChange={(_, page) => {
                        changePaginations("activePage", page);
                      }}
                    />
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default _;
