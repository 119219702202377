import React, { forwardRef } from "react";
import { IconButton, Box } from "@mui/material";
import MDInput from "./MDInput";
import SearchIcon from "@mui/icons-material/Search";

const _ = forwardRef(
  (
    {
      placeholder = "검색어를 입력하세요.",
      value,
      onChange,
      onBlur,
      handleSearch,
      fullWidth = true,
      ...rest
    },
    ref
  ) => {
    return (
      <MDInput
        inputRef={ref}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch(value);
          }
        }}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ "aria-label": "검색" }}
        {...rest}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => handleSearch(value)}>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth={fullWidth}
      />
    );
  }
);

export default _;
