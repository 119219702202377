import React, { useCallback, useEffect, useMemo, useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../LayoutContainer/DashboardLayout";
import MDButton from "../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Card, Grid, Box } from "@mui/material";
import FormItem from "../../components/FormItem";
import StationMappField from "../elements/StationMappField";
import { axios } from "../../common";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import dayjs from "dayjs";
import DataTable from "../../components/DataTable";
import useMuiConfirm from "../../hooks/useMuiConfirm";

const HEAD_CELLS = [
  { id: "rowNumber", label: "No", width: "80px", isMd: true },
  { id: "invSerialNumber", label: "인버터 시리얼번호" },
  { id: "hmiVer", label: "HmiVer" },
  {
    id: "creDate",
    label: "등록시간",
    format: (value) => dayjs(value).format("YYYY-MM-DD hh:mm:ss"),
  },
  {
    id: "invTempNo",
    isMd: true,
    isButton: "deleteTempInverter",
    format: ({ value, fn }) => (
      <MDButton
        variant="outlined"
        size="small"
        color="info"
        onClick={() => {
          fn(value);
        }}
      >
        삭제
      </MDButton>
    ),
  },
];

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { id } = location?.state || {};

  const [tempItem, setTempItem] = useState();

  const loadTempInverter = useCallback(async () => {
    try {
      const { status, data } = await axios.get("/certify/inverter/temp");

      if (status === 200) {
        setTempItem({ items: data.data });
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    loadTempInverter();
  }, [loadTempInverter]);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      station: "",
      invOrder: "",
      invModel: "",
      invCapacity: "",
      invSerialNumber: "",
      invMacadd: "",
      hmiVer: "",
    },
  });

  const loadInverter = useCallback(
    async (paramId) => {
      try {
        const { status, data } = await axios.get(
          `/certify/inverter/${paramId}`
        );

        if (status === 200) {
          if (data.data.stationNo) {
            setValue("station", {
              stationNo: data.data.stationNo,
              title: `${data.data.stationName}(${data.data.stationNo})`,
            });
          }
          setValue("invOrder", data.data.invOrder);
          setValue("invModel", data.data.invModel);
          setValue("invCapacity", data.data.invCapacity);
          setValue("invSerialNumber", data.data.invSerialNumber);
          setValue("invMacadd", data.data.invMacadd);
          setValue("hmiVer", data.data.hmiVer);
        }
      } catch (e) {}
    },
    [setValue]
  );

  useEffect(() => {
    if (id) {
      loadInverter(id);
    }
  }, [id, loadInverter]);

  const onSubmit = useMemo(
    () =>
      debounce(async (form) => {
        try {
          const { status } = await axios[id ? "patch" : "post"](
            "/certify/inverter",
            {
              ...(id ? { invNo: id } : {}),
              stationNo: form.station.stationNo,
              invOrder: form.invOrder,
              invModel: form.invModel,
              invSerialNumber: form.invSerialNumber,
              invMacadd: form.invMacadd,
              invCapacity: form.invCapacity,
              hmiVer: form.hmiVer,
            }
          );

          if (status === 200) {
            alert(`인버터 ${id ? "수정" : "등록"} 성공`);
            setTimeout(() => {
              navigate("/certify/inverters");
            }, 10);
          }
        } catch (e) {}
      }, 300),
    [id, navigate]
  );

  const handleCancel = useMemo(
    () =>
      debounce(() => {
        navigate(-1);
      }, 300),
    [navigate]
  );

  const handleChoice = useCallback(
    (invNo) => {
      const f = tempItem?.items.find((f) => f.invTempNo === invNo);

      //console.log({ f, invNo, tempItem });

      if (f) {
        setValue("hmiVer", f.hmiVer);
        setValue("invSerialNumber", f.invSerialNumber);
      }
    },
    [tempItem, setValue]
  );

  const [confirmMessage, setConfirmMessage] = useState();
  const [ConfirmDeleteDialog, confirmDelete] = useMuiConfirm(
    "삭제확인",
    confirmMessage
  );

  const handleDelete = useMemo(
    () =>
      debounce(async () => {
        try {
          setConfirmMessage("인버터를 삭제할 경우 데이터까지 삭제됩니다.");
          const confirm = await confirmDelete();
          if (confirm) {
            const { status } = await axios.delete("/certify/inverter", {
              params: { invNo: id },
            });

            if (status === 200) {
              alert("삭제 완료되었습니다.");
              setTimeout(() => {
                navigate("/certify/inverters");
              }, 10);
            }
          }
        } catch (e) {}
      }, 300),
    [confirmDelete, id, navigate]
  );

  const handleDeleteTempInverter = useMemo(
    () =>
      debounce(async (paramId) => {
        try {
          setConfirmMessage("대기중인 CB를 삭제하시겠습니까?");
          const confirm = await confirmDelete();
          if (confirm) {
            const { status } = await axios.delete("/certify/inverter-temp", {
              params: { invNo: paramId },
            });
            if (status === 200) {
              alert("삭제 완료되었습니다.");
              loadTempInverter();
            }
          }
        } catch (e) {}
      }, 300),
    [confirmDelete, loadTempInverter]
  );

  const handleDeleteTempInverterAll = useMemo(
    () =>
      debounce(async () => {
        try {
          setConfirmMessage("대기중인 CB전체를 삭제하시겠습니까?");
          const confirm = await confirmDelete();

          if (confirm) {
            const { status } = await axios.delete("/certify/inverter-temp-all");

            if (status === 200) {
              alert("삭제 완료되었습니다.");
              loadTempInverter();
            }
          }
        } catch (e) {}
      }, 300),
    [confirmDelete, loadTempInverter]
  );

  return (
    <DashboardLayout>
      <ConfirmDeleteDialog />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              인버터 {id ? "수정" : "등록"}
            </MDTypography>
          </MDBox>

          <MDBox pt={3} py={2} px={2}>
            <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton
                variant="contained"
                size="small"
                startIcon={<DeleteOutlineOutlinedIcon />}
                onClick={handleDeleteTempInverterAll}
                color="error"
              >
                전체삭제
              </MDButton>
            </MDBox>
            <DataTable
              headCells={HEAD_CELLS}
              data={tempItem}
              orderAlign="asc"
              columnId={"invTempNo"}
              onHref={handleChoice}
              deleteTempInverter={handleDeleteTempInverter}
              emptyMessage="대기중인 CB 목록이 없습니다."
            />
          </MDBox>
          <MDBox pt={3} py={2} px={2}>
            <Grid
              container
              rowSpacing={4.5}
              columnSpacing={2.75}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="invOrder"
                  rules={{
                    required: "[필수] 인버터 국번을 입력하여 주세요.",
                  }}
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="인버터 국번"
                      isRequired
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="invModel"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="인버터 모델"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="invCapacity"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="인버터 용량"
                      isRequired
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="invSerialNumber"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="인버터 S/N"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="invMacadd"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="MAC 주소"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="hmiVer"
                  render={({ field: { value, onChange, onBlur, ref } }) => (
                    <FormItem
                      label="HMI 버전"
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDTypography variant="h6">연결발전소</MDTypography>
                <Controller
                  control={control}
                  name="station"
                  render={({ field: { value, onChange, ref } }) => (
                    <StationMappField
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: theme.typography.pxToRem(10),

                    "& button": {
                      height: "50px",
                      width: "200px",
                    },

                    "& button + button": {
                      marginLeft: theme.typography.pxToRem(10),
                    },
                  }}
                >
                  <MDButton
                    variant="outlined"
                    size="large"
                    startIcon={<CancelOutlinedIcon />}
                    onClick={handleCancel}
                    color="info"
                  >
                    취소
                  </MDButton>

                  <MDButton
                    variant="contained"
                    size="large"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={handleSubmit(onSubmit)}
                    color="success"
                  >
                    {id ? "수정" : "등록"}
                  </MDButton>
                  {id && (
                    <MDButton
                      variant="contained"
                      size="large"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={handleDelete}
                      color="error"
                    >
                      삭제
                    </MDButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;
