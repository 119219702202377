import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";

import {
  Card,
  IconButton,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { debounce } from "loadsh";

import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import RefreshIcon from "@mui/icons-material/Refresh";
import DashboardCard from "../components/DashboardCard";
import MDInput from "../components/MDInput";
import SearchField from "../components/SearchField";
import MDBadge from "../components/MDBadge";

import DataTable from "../components/DataTable";
import {
  axios,
  consts,
  regex,
  formatWatt,
  numberFixedFormat,
  splitIntoChunk,
} from "../common";
import { useAuth } from "../AuthProvider";
import dayjs from "dayjs";
import SolarPowerIcon from "@mui/icons-material/SolarPower";

import AssessmentIcon from "@mui/icons-material/Assessment";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import GridViewIcon from "@mui/icons-material/GridView";
import TableViewIcon from "@mui/icons-material/TableView";

import qs from "qs";

import useInterval from "../hooks/useInterval";
import { useTranslation } from "react-i18next";

import DashboardItem from "../components/DashboardItem";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import { Grid as SGrid, Pagination } from "swiper/modules";

const _ = () => {
  const theme = useTheme();
  //const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [tabValue, setTabVlue] = useState(0);
  const handleTabChange = useCallback((event, newValue) => {
    setTabVlue(newValue);
  }, []);

  const { t } = useTranslation();

  const { user } = useAuth();

  const isChunk = useRef(false);
  const stationInfo = useRef();

  // 데이터
  const [initital, setInitial] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [totalCurrent, setTotalCurrent] = useState(0);
  const [totalEnergy, setTotalEnergy] = useState(0);
  const [totalCo2Reduction, setTotalCo2Reduction] = useState(0);
  const [totalMonthly, setTotalMonthly] = useState(0);

  const loadDahboardEnergy = useCallback(async (stations) => {
    try {
      let currentPower = 0,
        todayTotal = 0,
        co2Reduction = 0,
        monthlySum = 0;

      for (const value of stations) {
        const { data, status } = await axios.get("station/dashboard-energy", {
          params: { stationId: value.stationNo },
        });

        if (status === 200) {
          currentPower += data.data.invPower;
          todayTotal += data.data.todayTotal;
          co2Reduction += data.data.co2Reduction;
          monthlySum += data.data.monthlyTotal;

          console.log(data.data.monthlyTotal);

          setData((prev) => ({
            ...prev,
            stations: prev.stations.map((item) => ({
              ...item,
              ...(item.stationNo === data.data.stationNo
                ? {
                    invPower: data.data.invPower,
                    todayTotal: data.data.todayTotal,
                    co2Reduction: data.data.co2Reduction,
                    lastedLogDate: data.data.lastedLogDate,
                    statusCode: data.data.statusCode,
                    monthlySum: data.data.monthlyTotal,
                  }
                : {}),
            })),
          }));
        }
      }
      setIsLoading(false);

      //https://constructionsite.tistory.com/43

      // stations.forEach(async (el) => {
      //   const { data, status } = await axios.get("station/dashboard-energy", {
      //     params: { stationId: el.stationNo },
      //   });

      //   if (status === 200) {
      //     currentPower = Number(currentPower + data.data.invPower);
      //     todayTotal = Number(todayTotal + data.data.todayTotal);
      //   }
      // });

      setTotalCurrent(currentPower);
      setTotalEnergy(todayTotal);
      setTotalCo2Reduction(co2Reduction);
      setTotalMonthly(monthlySum);
    } catch (e) {}
  }, []);

  const loadDashboard = useCallback(async (cusId, init = true) => {
    try {
      const { status, data } = await axios.get("station/dashboard", {
        params: { cusId },
      });

      if (status === 200) {
        setData(data.data);

        //stationInfo.current = data.data.stations;
        const stations = data.data.stations;
        if (stations.length < 10) {
          stationInfo.current = stations;
        } else {
          // chunkArray
          isChunk.current = true;
          const chunkArray = splitIntoChunk(stations, 10);
          stationInfo.current = chunkArray;
        }

        //loadDahboardEnergy(data.data.stations);

        if (!init) {
          setInitial(true);
        }
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (user?.id) {
      if (!initital) {
        loadDashboard(user?.id, initital);
        setIsLoading(true);
      }
    }
  }, [user, initital, loadDashboard]);

  useEffect(() => {
    if (initital) {
      if (!isChunk.current) {
        loadDahboardEnergy(stationInfo.current);
      }
    }
  }, [initital, loadDahboardEnergy]);

  // 시간 상태 값
  const [currentTime, setCurrentTime] = useState(dayjs());
  useInterval(() => {
    setCurrentTime(dayjs());
  }, 1000);

  //계측값 갱신 타이머
  useInterval(() => {
    if (stationInfo.current) {
      loadDahboardEnergy(stationInfo.current);
    }
  }, 1000 * 60);

  // const loadTest = useCallback(async () => {
  //   try {
  //     let ids = [];
  //     ids.push(1);
  //     ids.push(2);

  //     const { status, data } = await axios.get("station/stationtest", {
  //       params: { stationIds: ids },
  //       paramsSerializer: (params) => {
  //         return qs.stringify(params);
  //       },
  //     });

  //     if (status === 200) {
  //       console.log({ data });
  //     }
  //   } catch (e) {}
  // }, []);

  // useEffect(() => {
  //   loadTest();
  // }, [loadTest]);

  return (
    <DashboardLayout>
      <MDBox sx={{ maxHeight: "100vh" }}>
        <MDBox py={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={3}>
              <DashboardCard
                icon={<SolarPowerIcon />}
                title={`${formatWatt(totalCurrent).v} ${
                  formatWatt(totalCurrent).f
                }`}
                description={t("station.currentPower")}
                //value="10 kW"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DashboardCard
                icon={<AssessmentIcon />}
                title={`${formatWatt(totalEnergy).v} ${
                  formatWatt(totalEnergy).f
                }h`}
                description={t("station.todayEnergy")}
                //value="10 kW"
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DashboardCard
                icon={<EnergySavingsLeafIcon />}
                title={`${formatWatt(totalMonthly).v} ${
                  formatWatt(totalMonthly).f
                }h`}
                //title={`${numberFixedFormat(totalCo2Reduction, 3)} t/CO2`}
                description={t("station.monthlyEnergy")}
                color="primary"
                //value="10 kW"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Card
                sx={{
                  minHeight: theme.typography.pxToRem(164.5),
                  maxHeight: theme.typography.pxToRem(164.5),
                }}
              >
                <MDBox p={2} mx={3} display="flex" justifyContent="center">
                  <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                    <MDTypography
                      variant="h5"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {`${formatWatt(data?.totalCapacity || 0).v} ${
                        formatWatt(data?.totalCapacity || 0).f
                      }p`}
                    </MDTypography>

                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      {t("station.installCapacity")}
                    </MDTypography>

                    <Divider />

                    <MDTypography variant="h6" fontWeight="medium">
                      {currentTime.format("YYYY-MM-DD HH:mm:ss")}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
              {/* <DashboardCard
              icon={<ElectricMeterIcon />}
              title="10 kW"
              description="실시간 발전량"
              //value="10 kW"
            /> */}
            </Grid>
            {/* <Grid item xs={12} md={4} xl={1.5}>
            <DashboardCard
              icon={<ElectricMeterIcon />}
              title="10 kW"
              description="실시간 발전량"
              //value="10 kW"
            />
          </Grid> */}
          </Grid>
        </MDBox>

        <MDBox sx={{}}>
          <Card sx={{ height: "100%" }}>
            <MDBox p={3}>
              {/* <Grid container>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="대시보드 뷰"
                    icon={<GridViewIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="테이블 뷰"
                    icon={<TableViewIcon />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <Divider /> */}

              <Box
                sx={{
                  "& .swiper": {
                    width: "100%",
                    height: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    //background: "blue",
                    //padding: 20,
                    paddingTop: 2,
                    paddingLeft: 1,
                    paddingRight: 1,
                    paddingBottom: 10,
                  },

                  // "& .swiper-slide": {
                  //   width: "100%",
                  //   display: "grid !important",
                  //   gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr ",
                  //   gridTemplateRows: "1fr 1fr 1fr",
                  //   gap: "10px, 10px",
                  // },

                  "& .swiper-slide": {
                    textAlign: "center",
                    fontSize: "18px",
                    //background: "red",
                    height: "calc((100% - 30px) / 2) !important",

                    /* Center slide text vertically */
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  grid={{
                    rows: 3,
                    fill: "row",
                  }}
                  pagination={{
                    dynamicBullets: true,

                    clickable: true,
                  }}
                  breakpoints={{
                    390: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },

                    576: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    930: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },

                    1300: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },

                    1500: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },

                    // 1600: {
                    //   slidesPerView: Math.ceil((1600 - 274) / 350),
                    //   spaceBetween: 10,
                    // },

                    1800: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },

                    2000: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },

                    2500: {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },

                    3000: {
                      slidesPerView: 8,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[SGrid, Pagination]}
                  className="mySwiper"
                >
                  {data?.stations.map((item) => (
                    <SwiperSlide key={item.stationNo}>
                      <DashboardItem
                        title={item.stationName}
                        invCapacity={item.invCapacity}
                        invPower={item.invPower}
                        todayTotal={item.todayTotal}
                        co2Reduction={item.co2Reduction}
                        lastedLogDate={item.lastedLogDate}
                        statusCode={item.statusCode}
                        isLoading={isLoading}
                        monthlySum={item.monthlySum}
                      />
                    </SwiperSlide>
                  ))}
                  {/* <SwiperSlide>
                  <DashboardItem title="광현1" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현2" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현3" />
                </SwiperSlide>

                <SwiperSlide>
                  <DashboardItem title="광현1" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현2" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현3" />
                </SwiperSlide>

                <SwiperSlide>
                  <DashboardItem title="광현1" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현2" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현3" />
                </SwiperSlide>

                <SwiperSlide>
                  <DashboardItem title="광현1" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현2" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현3" />
                </SwiperSlide>

                <SwiperSlide>
                  <DashboardItem title="광현1" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현2" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현3" />
                </SwiperSlide>

                <SwiperSlide>
                  <DashboardItem title="광현1" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현2" />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardItem title="광현3" />
                </SwiperSlide> */}

                  {/* <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide>
              <SwiperSlide>
                <DashboardItem title="광현" />
              </SwiperSlide> */}
                </Swiper>
              </Box>

              {/* <DashboardItem title="광현" /> */}

              {/* <MDBox position="relative" borderRadius="lg">
                <MDBox
                  component="img"
                  // src={image}
                  // alt={title}
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  height="100%"
                  position="relative"
                  zIndex={1}
                />
                <MDBox
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  height="100%"
                  position="absolute"
                  left={0}
                  top="3%"
                  sx={{
                    // backgroundImage: `url(${image})`,
                    transform: "scale(0.94)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }}
                />
              </MDBox>
              <MDBox p={3}>
                <MDTypography
                  display="inline"
                  variant="h3"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  abc
                </MDTypography>
                <MDBox mt={2} mb={3}>
                  <MDTypography variant="body2" component="p" color="text">
                    1234
                  </MDTypography>
                </MDBox>
              
              </MDBox> */}

              {/* <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="대시보드 뷰"
                      icon={<GridViewIcon />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="테이블 뷰"
                      icon={<TableViewIcon />}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3} xl={3}>
                <SearchField />
              </Grid>
              <Divider />
            </Grid> */}
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>

      {/* <MDBox>
        <MDBox sx={{ backgroundColor: "#fff", borderRadius: 1 }} p={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={6}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="대시보드 뷰"
                    icon={<GridViewIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="테이블 뷰"
                    icon={<TableViewIcon />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <SearchField />
            </Grid>
          </Grid>
        </MDBox>

        <CustomTabPanel value={value} index={0}>
          Item One
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>
      </MDBox> */}
    </DashboardLayout>
  );
};

export default _;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
