import React from "react";
import MDTypography from "./MDTypography";
import MDBox from "./MDBox";

const _ = ({ label, isRequired = false, error = false, helper = false }) => {
  return (
    <MDBox sx={{ display: "flex" }}>
      <MDTypography
        variant="h6"
        sx={{
          ...(error
            ? {
                fontSize: "13px",
                marginLeft: "6px",
                marginTop: "4px",
                fontWeight: "normal",
              }
            : helper
            ? {
                fontSize: "13px",
                marginLeft: "6px",
                marginTop: "4px",
                fontWeight: "normal",
              }
            : {}),
        }}
        color={error ? "error" : helper ? "info" : ""}
      >
        {label}
      </MDTypography>
      {isRequired && (
        <MDTypography
          variant="h6"
          sx={{
            color: "#ef3823",
            marginLeft: "4px",
          }}
        >
          *
        </MDTypography>
      )}
    </MDBox>
  );
};

export default _;
