function configs(configOptions, title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: configOptions?.xTitle || "",
          font: {
            family: "Noto Sans KR",
            size: 14,
            weight: "bold",
            style: "normal",
            lineHiehgt: 1.2,
          },
        },
        ...(configOptions?.xOtherOptions
          ? { ...configOptions.xOtherOptions }
          : {}),
      },

      y: {
        title: {
          display: true,
          text: configOptions?.yTitle || "",
          font: {
            family: "Noto Sans KR",
            size: 14,
            weight: "bold",
            style: "normal",
            lineHiehgt: 1.2,
          },
        },

        ...(configOptions?.yOtherOptions
          ? { ...configOptions.yOtherOptions }
          : {}),
      },
    },

    plugins: {
      legend: {
        position: "top",
        // labels: {
        //   font: {
        //     family: "Noto Sans KR",
        //     size: 11,
        //     style: "normal",
        //   },
        // },
      },
      title: {
        display: title ? true : false,
        text: title || "",
        font: {
          family: "Noto Sans KR",
          size: 18,
          weight: "bold",
          style: "normal",
        },
      },
      ...(configOptions?.tooltips ? { ...configOptions.tooltips } : {}),
      //tooltip: {
      // callbacks: {
      //   title: function (tooltipItem) {
      //     console.log({ tooltipItem });
      //     return tooltipItem;
      //   },
      //   label: function (context) {
      //     // console.log({ context });
      //     return `${context.dataset.label || ""} : ${context.raw.y}`;
      //   },
      // },
      //},
    },
  };
}

export default configs;
