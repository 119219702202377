import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  Fragment,
} from "react";

import {
  Grid,
  Card,
  useMediaQuery,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";
import dayjs from "dayjs";
import { debounce } from "loadsh";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import DataTable from "../components/DataTable";
import SearchField from "../components/SearchField";
import FileList from "../components/FileList";

import DatePickerField from "../components/DatePickerField";
import SelectField from "../components/SelectField";
import Pagination from "../components/Pagination";

import { numberFixedFormat, axios, consts, getColor, isEmpty } from "../common";
import queryString from "query-string";

import RefreshIcon from "@mui/icons-material/Refresh";

import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import JSZip from "jszip";
import { saveAs } from "file-saver";

import { useTranslation } from "react-i18next";

const HEAD_CELLS = [
  {
    id: "rowNumber",
    label: "No",
    isMd: true,
    sx: { maxWidth: "80px" },
  },
  { id: "title", label: "제목", isMd: true, align: "left" },
  {
    id: "writerName",
    label: "작성자",
    isMd: false,
    align: "left",
    sx: { minWidth: "80px" },
  },
  {
    id: "files",
    label: "첨부파일",
    isMd: false,
    format: (value) => (!isEmpty(value) ? <AttachFileOutlinedIcon /> : ""),
    sx: { maxWidth: "80px" },
  },
  {
    id: "updated",
    label: "작성일",
    isMd: true,
    format: (value) => dayjs(value).format("YYYY-MM-DD"),
    sx: { maxWidth: "80px" },
  },
];

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState();
  const [itemsPerPage, setItemsPerPage] = useState();
  const [activePage, setActivePage] = useState();

  const changePaginations = useCallback(
    (type, payload) => {
      if (!itemsPerPage) {
        return;
      }

      const params = new URLSearchParams();

      const i = type === "itemsPerPage" ? payload : itemsPerPage;
      params.append("items_per_page", `${i}`);

      const p = type === "activePage" ? payload : activePage;
      params.append("page", `${p}`);

      const qs = `?${params.toString()}`;
      if (location.search !== qs) {
        navigate(qs, { replace: !!location.search });
      }
    },
    [itemsPerPage, location, activePage, navigate]
  );

  const loadItems = useCallback(
    async (page, keyword = "") => {
      if (!page) return;

      try {
        const { status, data } = await axios.get("/announce", {
          params: {
            page: page,
            pageSize: itemsPerPage,
            keyword: keyword,
          },
        });

        if (status === 200) {
          setData(data.data);
          setIsPending(false);
        }
      } catch (error) {
        setTimeout(() => {
          setIsPending(false);
        }, 1);
      }
    },
    [itemsPerPage]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setItemsPerPage(Number.parseInt(params.get("items_per_page") || "20", 10));
    setActivePage(Number.parseInt(params.get("page") || "1", 10));
  }, [location]);

  useEffect(() => {
    setIsPending(true);
    loadItems(activePage);
  }, [loadItems, activePage]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const onSubmit = useMemo(
    () =>
      debounce((form) => {
        console.log({ form });
        loadItems(1, form.keyword);
      }, 300),
    [loadItems]
  );

  const [openId, setOpenId] = useState();

  // 개별 파일 다운로드
  const handleDownload = useMemo(
    () =>
      debounce(async (params) => {
        try {
          const { status, data } = await axios.get(
            `/announce/download/${params?.id}`,
            { responseType: "blob", timeout: 30000 }
          );

          if (status === 200) {
            saveAs(data, params?.fileName);
          }
        } catch (e) {}
      }, 300),
    []
  );

  // 전체파일 다운로드
  const handleDownloadAll = useMemo(
    (files) =>
      debounce(async () => {
        try {
          const possibleDownload = files.filter((f) => f.isUploaded);

          if (isEmpty(possibleDownload)) {
            alert("다운로드 가능한 파일이 없습니다.");
            return;
          }

          const zip = new JSZip();

          for (const fileItem of possibleDownload) {
            const { status, data } = await axios.get(
              `/announce/download/${fileItem.id}`,
              {
                responseType: "blob",
                timeout: 30000,
              }
            );

            if (status === 200) {
              zip.file(fileItem.fileName, data);
            }

            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "download");
            });
          }
        } catch (e) {}
      }, 300),
    []
  );

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Card>
          {/* <MDTypography>{t("nav.announce")}</MDTypography> */}
          {/* <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={3}
          >
            <MDTypography variant="h6" gutterBottom>
              공지사항
            </MDTypography>
            <IconButton onClick={() => {}}>
              <RefreshIcon />
            </IconButton>
          </MDBox> */}
          <MDBox p={3} sx={{ marginTop: 3 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <MDBox sx={{ maxWidth: "auto" }}>
                  <Controller
                    control={control}
                    name="keyword"
                    render={({ field: { value, onChange, onBlur, ref } }) => (
                      <SearchField
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        handleSearch={handleSubmit(onSubmit)}
                        sx={{ marginRight: theme.typography.pxToRem(2) }}
                        placeholder={t("labels.searchplaceholder")}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography></MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead sx={{ display: "contents" }}>
                      <TableRow>
                        {HEAD_CELLS.map((head) => {
                          const cell = (
                            <TableCell
                              key={head.id}
                              sx={{
                                color: "inherit",
                                textAlign: head?.align ? head.align : "center",
                                ///width: head?.width ? head.width : "auto",
                                ...(head?.sx ? { ...head.sx } : {}),
                              }}
                              //   {...(head?.rowSpan && { rowSpan: head.rowSpan(isMd) })}
                              //   {...(head?.colSpan && { colSpan: head.colSpan(isMd) })}
                            >
                              {t(`table.${head.id}`)}
                            </TableCell>
                          );

                          return isMd ? cell : head.isMd && cell;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ boxSizing: "border-box" }}>
                      {isPending ? (
                        <TableRow>
                          <TableCell
                            colSpan={
                              isMd
                                ? HEAD_CELLS.length
                                : HEAD_CELLS.filter((f) => !f?.isMd).length
                            }
                          ></TableCell>
                        </TableRow>
                      ) : isEmpty(data?.items) ? (
                        <TableRow>
                          <TableCell
                            colSpan={
                              isMd
                                ? HEAD_CELLS.length
                                : HEAD_CELLS.filter((f) => !f?.isMd).length
                            }
                          ></TableCell>
                        </TableRow>
                      ) : (
                        data.items.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <TableRow
                                onClick={() => {
                                  setOpenId((prev) =>
                                    prev === item?.id ? null : item.id
                                  );
                                }}
                                sx={
                                  openId === item.id
                                    ? { backgroundColor: "#efefef" }
                                    : {}
                                }
                              >
                                {HEAD_CELLS.map((column) => {
                                  const value =
                                    column.id === "rowNumber"
                                      ? index + 1
                                      : item[column.id];

                                  const cell = (
                                    <TableCell
                                      key={column.id + item.id}
                                      sx={{
                                        textAlign: column.align || "center",
                                      }}
                                    >
                                      {column.format
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );

                                  return isMd ? cell : column.isMd && cell;
                                })}
                              </TableRow>
                              {openId === item.id && (
                                <TableRow>
                                  <TableCell
                                    colSpan={
                                      isMd
                                        ? HEAD_CELLS.length
                                        : HEAD_CELLS.filter((f) => !f?.isMd)
                                            .length
                                    }
                                  >
                                    <Collapse
                                      in={openId === item.id}
                                      timeout={"auto"}
                                      unmountOnExit
                                    >
                                      <MDTypography
                                        component="div"
                                        variant="body2"
                                        sx={{ whiteSpace: "pre-wrap" }}
                                      >
                                        {item?.memo}
                                      </MDTypography>
                                      {/* {item?.memo} */}

                                      {!isEmpty(item?.files) && (
                                        <MDBox mt={3}>
                                          <MDBox
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <MDBox
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <AttachFileOutlinedIcon />
                                              <MDTypography variant="subtitle2">
                                                첨부파일
                                              </MDTypography>
                                            </MDBox>
                                            <MDButton
                                              variant="outlined"
                                              size="small"
                                              color="success"
                                              startIcon={
                                                <FileDownloadOutlinedIcon />
                                              }
                                              onClick={handleDownloadAll}
                                            >
                                              전체 다운로드
                                            </MDButton>
                                          </MDBox>
                                          <FileList
                                            uploadFiles={item?.files}
                                            onDownload={handleDownload}
                                          />
                                        </MDBox>
                                      )}
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Fragment>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <DataTable
                  headCells={HEAD_CELLS}
                  data={data}
                  isLoading={isPending}
                  columnId={"id"}
                /> */}
              </Grid>
              <Grid item xs={12}>
                {data?.items && (
                  <Pagination
                    page={activePage}
                    count={
                      itemsPerPage
                        ? Math.ceil(data.totalItemCount / itemsPerPage)
                        : 0
                    }
                    onChange={(_, page) =>
                      changePaginations("activePage", page)
                    }
                  />
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default _;
