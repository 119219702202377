import React, { useEffect, useCallback, useState } from "react";

import { Grid, Card, Typography, Box } from "@mui/material";

import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDBadge from "../components/MDBadge";
import ComplexStatisticsCard from "./elements/ComplexStatisticsCard";
import dayjs from "dayjs";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import BarChartIcon from "@mui/icons-material/BarChart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import DataTable from "../components/DataTable";
import Axios from "axios";
import { useTheme } from "@mui/material/styles";

import {
  numberFixedFormat,
  //durationMinute,
  axios,
  regex,
  consts,
} from "../common";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
//Wimport LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import useInterval from "../hooks/useInterval";
import { useTranslation } from "react-i18next";

const HEAD_CELLS = [
  {
    id: "invOrder",
    label: "국번",
    rowSpan: (isMd) => 2,
    align: "center",
    isMd: true,
  },

  {
    id: "invCapacity",
    scale: "kWp",
    rowSpan: (isMd) => 2,
    align: "center",
    isMd: false,
  },

  {
    id: "input",
    label: "입력측",
    colSpan: (isMd) => (isMd ? 3 : 1),
    align: "center",
    nested: [
      {
        id: "solarVolt",
        label: "전압",
        align: "center",
        scale: "V",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
      {
        id: "solarCurrent",
        label: "전류",
        align: "center",
        scale: "A",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },

      {
        id: "solarPower",
        label: "전력",
        align: "center",
        scale: "kW",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
    ],
    isMd: false,
  },

  {
    id: "output",
    label: "출력측",
    colSpan: (isMd) => (isMd ? 3 : 1),
    align: "center",
    nested: [
      {
        id: "lineVoltRs",
        label: "전압",
        align: "center",
        scale: "V",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
      {
        id: "lineCurrR",
        label: "전류",
        align: "center",
        scale: "A",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },

      {
        id: "invPower",
        label: "전력",
        align: "center",
        scale: "kW",
        format: (value) => numberFixedFormat(value, 0),
        isMd: true,
      },
    ],
    isMd: true,
  },

  {
    id: "invPf",
    label: "역률",
    align: "center",
    rowSpan: (isMd) => 2,
    scale: "%",
    format: (value) => numberFixedFormat(value, 1),
    isMd: false,
  },
  {
    id: "lineFrequency",
    label: "주파수",
    align: "center",
    rowSpan: (is) => 2,
    scale: "Hz",
    format: (value) => numberFixedFormat(value, 1),
    isMd: false,
  },
  {
    id: "power",
    label: "발전량",
    align: "center",
    colSpan: (isMd) => (isMd ? 2 : 1),
    nested: [
      {
        id: "invDaily",
        label: "금일",
        align: "center",
        scale: "kWh",
        format: (value) => numberFixedFormat(value, 0),
        isMd: true,
      },
      {
        id: "invTotal",
        label: "누적",
        align: "center",
        scale: "kWh",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
    ],
    isMd: true,
  },
  //   {
  //     id: "todayCo2",
  //     label: "CO2 저감량",
  //     align: "center",
  //     scale: "Co2",
  //     rowSpan: 2,
  //     format: (value) => numberFixedFormat(value, 0),
  //     isMd: false,
  //   },
  {
    id: "statusCode",
    label: "상태",
    align: "center",
    rowSpan: (isMd) => 2,
    format: (value) => (
      <MDBadge
        color={consts.STATUS_CODE.find((f) => f.code === value)?.color}
        variant="gradient"
        size="md"
        badgeContent={consts.STATUS_CODE.find((f) => f.code === value)?.text}
        container
      />
    ),
    isMd: true,
  },
];

//usp_getInverterinfo 요거 검사 ㅋㅋㅋ

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { station } = useAuth();

  const theme = useTheme();

  const [timer, setTimer] = useState(dayjs());

  const [initital, setInitital] = useState(true);
  const [isPending, setIsPending] = useState(true);
  const [data, setData] = useState();

  const loadEnergy = useCallback(async (stationId) => {
    try {
      const { status, data } = await axios.get("station/energy", {
        params: { stationId },
      });

      if (status === 200) {
        const energy = data.data;
        setData({ ...energy, items: energy.invItem });
        setInitital(false);
        setIsPending(false);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (station?.stationNo) {
      setInitital(true);
      setIsPending(true);
      loadEnergy(station?.stationNo);
    }
  }, [loadEnergy, station]);

  useInterval(() => {
    if (station?.stationNo && !isPending) {
      setIsPending(true);
      loadEnergy(station?.stationNo);
    }
  }, 15000);

  useInterval(() => {
    setTimer(dayjs());
  }, 1000);

  const [weathers, setWeathers] = useState();

  const loadWeather = useCallback(async (lat, lon) => {
    try {
      const { status, data } = await Axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${process.env.REACT_APP_OPENWEATHERMAP_KEY}`
      );
      if (status === 200) {
        setWeathers(data);
      }
    } catch (e) {
      console.log({ e });
    }
  }, []);

  useEffect(() => {
    if (station?.lat) {
      loadWeather(station?.lat, station?.lon);
    }
  }, [station, loadWeather]);

  return (
    <DashboardLayout>
      {!station?.stationNo ? (
        <MDBox py={3} sx={{ textAlign: "center" }}>
          <MDTypography variant="h6" color="info" textGradient>
            {t("emptyMessage.emptyStation")}
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <Card>
                  <MDBox display="flex" pt={3} px={2} alignItems="center">
                    <InfoOutlinedIcon />
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      sx={{ marginLeft: theme.typography.pxToRem(2) }}
                    >
                      {t("station.info")}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                    >
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        bgColor={"grey-100"}
                        borderRadius="lg"
                        p={3}
                        mb={0}
                        mt={2}
                      >
                        <MDBox
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                            mb={2}
                          >
                            <MDTypography
                              variant="button"
                              //                        variant=""
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {station?.stationName}
                            </MDTypography>
                          </MDBox>
                          <MDBox mb={1} lineHeight={0}>
                            <MDTypography variant="caption" color="text">
                              {t("station.address")}&nbsp;:&nbsp;&nbsp;&nbsp;
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                                textTransform="capitalize"
                              >
                                {station?.stationAddress}
                              </MDTypography>
                            </MDTypography>
                          </MDBox>
                          <MDBox mb={1} lineHeight={0}>
                            <MDTypography variant="caption" color="text">
                              {t("station.installCapacity")}
                              &nbsp;:&nbsp;&nbsp;&nbsp;
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                              >
                                {`${numberFixedFormat(
                                  station?.deviceSum,
                                  0
                                )} kWp`}
                              </MDTypography>
                            </MDTypography>
                          </MDBox>
                          <MDBox mb={1} lineHeight={0}>
                            <MDTypography variant="caption" color="text">
                              {t("station.operation")}&nbsp;:&nbsp;&nbsp;&nbsp;
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                              >
                                {dayjs(station?.firstOperatingDate).format(
                                  "YYYY-MM-DD"
                                )}
                              </MDTypography>
                            </MDTypography>
                          </MDBox>
                          <MDBox mb={0} lineHeight={0}>
                            <MDTypography variant="caption" color="text">
                              {t("station.ip")}&nbsp;:&nbsp;&nbsp;&nbsp;
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                              >
                                {station?.publicIp || ""}
                              </MDTypography>
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <MDBox display="flex" pt={3} px={2} alignItems="center">
                    <MyLocationOutlinedIcon />
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      sx={{ marginLeft: theme.typography.pxToRem(2) }}
                    >
                      {t("station.weather")}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                    >
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        bgColor={"grey-100"}
                        borderRadius="lg"
                        p={3}
                        mb={0}
                        mt={2}
                      >
                        <MDBox
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                            mb={2}
                          >
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {station?.stationAddress}
                            </MDTypography>
                          </MDBox>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ marginLeft: 1 }}>
                              {weathers?.weather[0]?.icon && (
                                <img
                                  src={`https://openweathermap.org/img/w/${weathers?.weather[0]?.icon}.png`}
                                  alt="weather icon"
                                />
                              )}
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  fontWeight: 500,
                                  textAlign: "center",
                                }}
                              >
                                {weathers?.weather[0].id &&
                                  consts.WEATHER_TEXT.find(
                                    (f) => f.code === weathers?.weather[0].id
                                  )?.text}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                //flex: 1,

                                //flexGrow: 1,
                                marginRight: 5,

                                //backgroundColor: "red",
                                alignSelf: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  marginBlock: theme.typography.pxToRem(3),
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: "center",
                                  }}
                                >
                                  {t("station.wind")}&nbsp;:&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: "center",
                                  }}
                                >
                                  {`${weathers?.wind.speed || 0} m/s`}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  marginBlock: theme.typography.pxToRem(3),
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: "center",
                                  }}
                                >
                                  {t("station.temp")}&nbsp;:&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: "center",
                                  }}
                                >
                                  {`${
                                    weathers?.main.temp
                                      ? Math.round(
                                          weathers?.main.temp - 273.15,
                                          1
                                        )
                                      : 0
                                  } ℃`}
                                </Typography>
                              </Box>

                              <Box sx={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: "center",
                                  }}
                                >
                                  {t("station.humidity")}
                                  &nbsp;:&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: "center",
                                  }}
                                >
                                  {`${weathers?.main.humidity || 0} %`}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Card>
                  <MDBox display="flex" pt={3} px={2} alignItems="center">
                    <AccessTimeOutlinedIcon />
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      sx={{ marginLeft: theme.typography.pxToRem(2) }}
                    >
                      {t("station.timer")}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox p={0} m={0}>
                      <MDBox
                        // justifyContent="space-between"
                        // alignItems="center"
                        bgColor={"grey-100"}
                        borderRadius="lg"
                        p={3}
                        mb={0}
                        mt={2}
                        sx={{
                          minHeight: theme.typography.pxToRem(166),
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",

                          //backgroundColor: "red",
                        }}
                      >
                        <MDTypography
                          color={"secondary"}
                          sx={{
                            fontSize: 20,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          {timer.format("YYYY-MM-DD HH:mm:ss")}
                        </MDTypography>
                        <MDTypography
                          color={"secondary"}
                          fontWeight="light"
                          variant="button"
                        >
                          {data?.lastedLogDate
                            ? dayjs(data?.lastedLogDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : t("emptyMessage.noUpdated")}
                        </MDTypography>
                        <MDTypography
                          color={"secondary"}
                          fontWeight="light"
                          variant="caption"
                        >
                          (Last update time)
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon={<ElectricMeterIcon />}
                    title={t("station.currentPower")}
                    count={`${
                      data?.currentPower
                        ? numberFixedFormat(data.currentPower, 1)
                        : 0
                    } kW`}
                    percentage={{
                      color: "success",
                      amount: `${
                        data?.currentUsed
                          ? numberFixedFormat(data?.currentUsed, 0)
                          : 0
                      }%`,
                      label: t("station.currentPowerRate"),
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon={<BarChartIcon />}
                    title={t("station.todayEnergy")}
                    count={`${
                      data?.todayTotal
                        ? numberFixedFormat(data?.todayTotal, 0)
                        : 0
                    } kWh`}
                    percentage={{
                      color: "success",
                      amount: `${
                        data?.todayHour
                          ? numberFixedFormat(data?.todayHour, 2)
                          : 0
                      }`,
                      label: t("station.todayEnergyHour"),
                    }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon={<LeaderboardIcon />}
                    title={t("station.monthlyEnergy")}
                    count={`${
                      data?.monthlySum
                        ? numberFixedFormat(data?.monthlySum / 1000, 2)
                        : 0
                    } MWh`}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: t("station.monthlyEnergyHelp"),
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon={<LeaderboardIcon />}
                    title={t("station.totalEnergy")}
                    count={`${
                      data?.totalSum
                        ? numberFixedFormat(
                            data?.totalSum / 1000 / 1000,
                            2
                          ).replace(regex.CURRENCY, ",")
                        : 0
                    } GWh`}
                    percentage={{
                      color: "success",
                      amount: dayjs().format("HH:mm"),
                      label: "Just updated",
                    }}
                  />
                </MDBox>
              </Grid>

              {/* https://velog.io/@hjkdw95/React-table%EC%9D%84-%EC%9D%B4%EC%9A%A9%ED%95%98%EC%97%AC-%EC%9E%AC%EC%82%AC%EC%9A%A9-%EA%B0%80%EB%8A%A5%ED%95%9C-table-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0 */}

              <Grid item xs={12} mt={6}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {t("station.inverterState")}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      headCells={HEAD_CELLS}
                      data={data}
                      emptyMessage={
                        initital
                          ? t("emptyMessage.loadingMessage")
                          : t("emptyMessage.nofoundMessage")
                      }
                      isLoading={initital}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default _;
