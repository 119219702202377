import React, { useEffect, useCallback, useState } from "react";

import { Grid, Card, useMediaQuery, IconButton } from "@mui/material";

import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import ComplexStatisticsCard from "./elements/ComplexStatisticsCard";
import dayjs from "dayjs";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import BarChartIcon from "@mui/icons-material/BarChart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import DataTable from "../components/DataTable";
import Axios from "axios";
import { useTheme } from "@mui/material/styles";
import DatePickerField from "../components/DatePickerField";
import SelectField from "../components/SelectField";

import {
  numberFixedFormat,
  durationMinute,
  axios,
  regex,
  consts,
} from "../common";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";

const HEAD_CELLS = [
  { id: "rowNumber", label: "No", width: "80px", isMd: true },
  { id: "invOrder", label: "발생장비", width: "150px", isMd: true },
  {
    id: "alertLogDate",
    label: "발생시각",
    format: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    isMd: true,
  },
  {
    id: "alertCode",
    label: "경보 내용",
    isMd: true,
    format: (value) => consts.ALARM_CODE.find((f) => f.value === value)?.label,
  },
];

const _ = () => {
  const { station } = useAuth();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [selectDate, setSelectDate] = useState(dayjs());
  const [selectDevice, setSelectDevice] = useState("all");
  const [deviceList, setDeviceList] = useState([]);

  useEffect(() => {
    if (station?.devices) {
      setDeviceList(station.devices);
    }
  }, [station]);

  const [isPending, setIsPending] = useState(true);
  const [data, setData] = useState();

  const loadAlarmHistory = useCallback(async (stationId, toDate) => {
    try {
      const { status, data } = await axios.get("station/alarm-history", {
        params: { stationId, toDate: dayjs(toDate).format("YYYY-MM-DD") },
      });

      if (status === 200) {
        setData({ items: data.data });
        setIsPending(false);
      }
    } catch (e) {
      setTimeout(() => {
        setIsPending(false);
        setData(null);
      }, 1);
    }
  }, []);

  useEffect(() => {
    if (station?.stationNo && selectDate) {
      setIsPending(true);
      loadAlarmHistory(station?.stationNo, selectDate);
    }
  }, [loadAlarmHistory, selectDate, station]);

  return (
    <DashboardLayout>
      {!station?.stationNo ? (
        <MDBox py={3} sx={{ textAlign: "center" }}>
          <MDTypography variant="h6" color="info" textGradient>
            {t("emptyMessage.emptyStation")}
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox py={3}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    {station?.stationName}
                  </MDTypography>
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {t("labels.historyHelp")}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox color="text" px={2}>
                  <IconButton>
                    <RefreshIcon />
                  </IconButton>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <DatePickerField
                      value={selectDate}
                      onChange={(e) => {
                        setSelectDate(e);
                      }}
                    />
                  </Grid>
                  {isMd && <Grid item md={4} lg={8} />}
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <MDBox display="flex" sx={{ alignItems: "center" }}>
                      {/* <MDTypography
                        variant="h6"
                        sx={{ width: theme.typography.pxToRem(60) }}
                      >
                        국번 :
                      </MDTypography> */}
                      <SelectField
                        value={selectDevice}
                        onChange={(e) => {
                          setSelectDevice(e.target.value);
                        }}
                        placeholder="국번을 선택하세요."
                        valueOptions={[
                          { value: "all", label: "전체" },
                          ...deviceList,
                        ]}
                        isClearButton={false}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <DataTable
                headCells={HEAD_CELLS}
                data={
                  selectDevice === "all"
                    ? data
                    : {
                        items: data.items.filter(
                          (f) => f.invNo === selectDevice
                        ),
                      }
                }
                emptyMessage="발생중인 알람이 없습니다."
                orderAlign="asc"
                isLoading={isPending}
              />
            </Card>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default _;
