import React, { forwardRef, useMemo } from "react";
import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { debounce } from "lodash";

const _ = forwardRef(
  (
    {
      fullWidth = true,
      size = "large",
      valueOptions = [],
      name,
      value,
      onChange,
      onBlur,
      placeholder,
      error = null,
      helperText,
      isClearButton = true,
    },
    ref
  ) => {
    const handleClear = useMemo(
      () =>
        debounce(() => {
          onChange({ target: { value: "", label: "" } });
        }, 300),
      [onChange]
    );

    return (
      <FormControl fullWidth={fullWidth} size={size} error={!!error}>
        <Select
          sx={{ height: "44px", "& svg": { display: "flex" } }}
          inputRef={ref}
          name={name}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          displayEmpty
          IconComponent={ExpandMoreIcon}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <div style={{ color: "#999", fontWeight: 400 }}>
                  {placeholder}
                </div>
              );
            }
            return valueOptions.find((f) => f.value === selected)?.label;
          }}
          inputProps={{ "aria-label": "Without label" }}
          {...(isClearButton
            ? {
                endAdornment: (
                  <IconButton
                    onClick={handleClear}
                    sx={{ visibility: value ? "visible" : "hidden" }}
                  >
                    <ClearOutlinedIcon />
                  </IconButton>
                ),
                sx: {
                  "& .MuiSelect-iconOutlined": {
                    display: value ? "none" : "",
                  },
                },
              }
            : {})}
        >
          {!!valueOptions &&
            valueOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>

        {!!error ? (
          <FormHelperText>{error}</FormHelperText>
        ) : (
          !!helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default _;
