const STATION_KEY = "_bravo_";

export const stationUtil = {
  getStationId,
  setStationId,
  clearStationId,
};

function getStationId() {
  return localStorage.getItem(STATION_KEY);
}

function setStationId(value) {
  localStorage.setItem(STATION_KEY, value);
}

function clearStationId() {
  localStorage.removeItem(STATION_KEY);
}
