import React from "react";
import { Pagination, Stack } from "@mui/material";

const _ = ({
  size = "small",
  count = 10,
  shape = "rounded",
  variant = "outlined",
  page,
  onChange,
  ...props
}) => {
  return (
    <Stack sx={{ alignItems: "center", mt: 2 }}>
      <Pagination
        count={count}
        size={size}
        variant={variant}
        shape={shape}
        page={page}
        onChange={onChange}
        {...props}
      />
    </Stack>
  );
};

export default _;
