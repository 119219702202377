import Axios from "axios";
import { auth } from "./utils/auth";
import { stationUtil } from "./utils/station";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const consts = {
  HANGUL_CHO: [
    "ㄱ",
    "ㄲ",
    "ㄴ",
    "ㄷ",
    "ㄸ",
    "ㄹ",
    "ㅁ",
    "ㅂ",
    "ㅃ",
    "ㅅ",
    "ㅆ",
    "ㅇ",
    "ㅈ",
    "ㅉ",
    "ㅊ",
    "ㅋ",
    "ㅌ",
    "ㅍ",
    "ㅎ",
  ],

  ROLES: [
    { value: 1, name: "Guest", label: "손님" },
    { value: 2, name: "Authority", label: "시스템관리자" },
    { value: 3, name: "Operator", label: "운영관리자" },
    { value: 4, name: "Supervisor", label: "그룹관리자" },
    { value: 5, name: "Owner", label: "사업주" },
    { value: 6, name: "Safety", label: "안전관리자" },
  ],

  ALARM_CODE: [
    {
      value: "SolarOvLimit",
      label: "태양전지 과전압",
      message:
        "인버터 PV 전압 확인 / 외기 온도 확인</br>인버터 리셋 후 5분 뒤 자동 기동확인",
    },
    {
      value: "SolarOvFault",
      label: "태양전지 과전압 제한초과",
      message:
        "인버터 PV 전압 확인 / 외기 온도 확인</br>인버터 리셋 후 5분 뒤 자동 기동확인",
    },
    {
      value: "SolarUvFault",
      label: "태양전지 저전압",
      message:
        "인버터 PV 전압 확인 / 날씨 확인</br>인버터 리셋 후 5분 뒤 자동 기동확인",
    },
    {
      value: "SolarUvLimit",
      label: "태양전지 저전압 제한초과",
      message:
        "5분 대기 후 자동 기동 확인 " +
        "&nbsp; &nbsp; &nbsp;  &nbsp;" +
        "PV출력전력이 적을 경우 정지발생 / 날씨 확인",
    },
    {
      value: "LineFailure",
      label: "계통 정전",
      message: "계통 전압 확인</br>정전 복귀 후 5분 뒤 자동 기동",
    },
    {
      value: "InvGroundFault",
      label: "절연 저항 이상",
      message:
        "인버터 리셋 및 5분 뒤 자동 기동 확인<br>인버터 계총 차단기측 CT 결선 상태 및 접촉 상태 확인",
    },
    {
      value: "InvOcFault",
      label: "인버터 과전류",
      message: "인버터 리셋 후 5분 뒤 자동기동 확인",
    },
    {
      value: "IgbtOverCurrent",
      label: "IGBT 과전류 이상",
      message: "인버터 리셋 및 5분 뒤 자동 기동 확인",
    },
    {
      value: "DclinkOverVolt",
      label: "DCLink 과전압 이상",
      message: "인버터 리셋 및 5분 뒤 자동 기동 확인",
    },
    {
      value: "InvMcStatus",
      label: "인버터 정지",
      message: "인버터 저전압 외의 정지는 고객센터로 연락주세요.",
    },
    {
      value: "InvFuseStatus",
      label: "팬 이상",
      message:
        "인버터 리셋 및 5분 뒤 자동기동 확인</br>인버터 내부 IGBT 및 PCB보드 소손 확인",
    },
    {
      value: "InvOtFault",
      label: "인버터 과온",
      message: "인버터실 내부 온도 확인</br>인버터 내부 팬 동작 확인",
    },
    { value: "EpoState", label: "비상정지", message: "" },
    {
      value: "InvMcFault",
      label: "인버터 MC 이상",
      message: "인버터 리셋 후 5분 뒤 자동기동 확인",
    },
    {
      value: "InvOutvoltFault",
      label: "INV DSAT",
      message: "인버터 리셋 후 5분 뒤 자동기동 확인",
    },
    {
      value: "InvFrequencyFault",
      label: "INV UVLO",
      message: "인버터 리셋 및 5분 뒤 자동 기동 확인",
    },
    {
      value: "LineInvAsyncFault",
      label: "잔류 전류 이상",
      message: "인버터 리셋 후 5분 뒤 자동기동 확인",
    },
    {
      value: "LineSequencyFault",
      label: "계통 역상",
      message:
        "AC 계통 입력 결선된 상 순서 확인</br>역상시 R상 => S상 순서 변경",
    },
    {
      value: "LineUvFault",
      label: "계통 저전압",
      message:
        "계통 전압확인</br>계측 전압이 계통 저전압 범위 이상시 계통 점검",
    },
    {
      value: "LineOvFault",
      label: "계통 과전압",
      message:
        "계통 전압확인</br>계측 전압이 계통 과전압 범위 이상시 계통 점검",
    },
    {
      value: "LineUfFault",
      label: "계통 저주파수",
      message:
        "순간적인 주파수 변동에 의한 발생 가능</br>정상 복귀 후 5분 뒤 자동기동 확인",
    },
    {
      value: "LineOfFault",
      label: "계통 과주파수",
      message:
        "순간적인 주파수 변동에 의한 발생 가능</br>정상 복귀 후 5분 뒤 자동기동 확인",
    },
    { value: "InvOcOverTimeFault", label: "", message: "" },
  ],

  WEATHER_TEXT: [
    { code: 200, text: "비/천둥", textEng: "" },
    { code: 201, text: "비/천둥", textEng: "" },
    { code: 202, text: "비/천둥", textEng: "" },
    { code: 210, text: "천둥/구름", textEng: "" },
    { code: 211, text: "천둥/구름", textEng: "" },
    { code: 212, text: "천둥/구름", textEng: "" },
    { code: 221, text: "천둥/구름", textEng: "" },
    { code: 230, text: "천둥/구름", textEng: "" },
    { code: 231, text: "천둥/구름", textEng: "" },
    { code: 232, text: "비/천둥/구름", textEng: "" },
    { code: 300, text: "가벼운 비", textEng: "" },
    { code: 302, text: "가벼운 비", textEng: "" },
    { code: 310, text: "약한 비", textEng: "" },
    { code: 311, text: "약한 비", textEng: "" },
    { code: 312, text: "약한 비", textEng: "" },
    { code: 313, text: "소나기", textEng: "" },
    { code: 314, text: "소나기", textEng: "" },
    { code: 321, text: "소나기", textEng: "" },
    { code: 500, text: "약한 비", textEng: "" },
    { code: 501, text: "비", textEng: "" },
    { code: 502, text: "강한 비", textEng: "" },
    { code: 503, text: "매우 강한 비", textEng: "" },
    { code: 504, text: "매우 강한 비", textEng: "" },
    { code: 511, text: "우박", textEng: "" },
    { code: 520, text: "약한 소나기", textEng: "" },
    { code: 521, text: "소나기", textEng: "" },
    { code: 522, text: "소나기", textEng: "" },
    { code: 531, text: "소나기", textEng: "" },
    { code: 600, text: "눈", textEng: "" },
    { code: 601, text: "눈", textEng: "" },
    { code: 602, text: "눈", textEng: "" },
    { code: 611, text: "진눈깨비", textEng: "" },
    { code: 612, text: "진눈깨비", textEng: "" },
    { code: 615, text: "비와 눈", textEng: "" },
    { code: 616, text: "비와 눈", textEng: "" },
    { code: 620, text: "비와 눈", textEng: "" },
    { code: 621, text: "비와 눈", textEng: "" },
    { code: 622, text: "비와 눈", textEng: "" },
    { code: 701, text: "박무", textEng: "" },
    { code: 711, text: "연기", textEng: "" },
    { code: 721, text: "연무", textEng: "" },
    { code: 731, text: "모래 먼지", textEng: "" },
    { code: 741, text: "안개", textEng: "" },
    { code: 751, text: "모래", textEng: "" },
    { code: 761, text: "먼지", textEng: "" },
    { code: 762, text: "화산재", textEng: "" },
    { code: 771, text: "돌풍", textEng: "" },
    { code: 781, text: "토네이도", textEng: "" },
    { code: 800, text: "맑음", textEng: "" },
    { code: 801, text: "약한 구름", textEng: "" },
    { code: 802, text: "구름", textEng: "" },
    { code: 803, text: "약간 흐림", textEng: "" },
    { code: 804, text: "흐림", textEng: "" },
    { code: 900, text: "토네이도", textEng: "" },
    { code: 901, text: "태풍", textEng: "" },
    { code: 902, text: "허리케인", textEng: "" },
    { code: 903, text: "한랭", textEng: "" },
    { code: 904, text: "고온", textEng: "" },
    { code: 905, text: "바람", textEng: "" },
    { code: 906, text: "우박", textEng: "" },
    { code: 951, text: "맑음", textEng: "" },
    { code: 952, text: "바람", textEng: "" },
    { code: 953, text: "바람", textEng: "" },
    { code: 954, text: "바람", textEng: "" },
    { code: 955, text: "바람", textEng: "" },
    { code: 956, text: "바람", textEng: "" },
    { code: 957, text: "바람", textEng: "" },
    { code: 958, text: "돌풍", textEng: "" },
    { code: 959, text: "돌풍", textEng: "" },
    { code: 960, text: "태풍", textEng: "" },
    { code: 961, text: "태풍", textEng: "" },
    { code: 962, text: "태풍", textEng: "" },
  ],

  STATUS_CODE: [
    { code: "offline", text: "통신장애", color: "light" },
    { code: "runing", text: "동작중", color: "success" },
    { code: "stanby", text: "대기중", color: "warning" },
    { code: "stop", text: "정지중", color: "error" },
  ],

  IVT_TARGET: [
    { value: "solarPower", label: "입력전력", x: "kW" },
    { value: "invPower", label: "출력전력", x: "kW" },
    { value: "solarVolt", label: "입력전압", x: "Volt" },
    { value: "lineVoltRs", label: "출력전압", x: "volt" },
    { value: "solarCurrent", label: "입력전류", x: "Current" },
    { value: "lineCurrR", label: "출력전류", x: "Current" },
  ],
};

// 날씨 코드의 정확한 번역
// https://gist.github.com/choipd/e73201a4653a5e56e830

const openSidebar = () => {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  }
};

const closeSidebar = () => {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
  }
};

const toggleSidebar = () => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
};

const regex = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  CELLPHONE: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
  CELLPHONE_NUMBERONLY: /^01[016789]{1}\d{7,8}$/,
  PASSWORD: /^.*(?=^.{8,20}$)(?=.*[a-z|A-Z])(?=.*\d)(?=.*[.!@#$%]).*$/,
  CURRENCY: /\B(?=(\d{3})+(?!\d))/g,
  DATE: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
};

const numberFixedFormat = (value, n) => {
  return String(parseFloat(Number(value).toFixed(n))).replace(
    regex.CURRENCY,
    ","
  );
};

const durationMinute = (val) => {
  return dayjs.duration(dayjs().diff(dayjs(val))).asMinutes();
};

const isEmpty = (value) => {
  return !!(
    value === "" ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === "object" && !Object.keys(value).length)
  );
};

const COLORS = [
  "#4dc9f6",
  "#f67019",
  "#f53794",
  "#537bc4",
  "#acc236",
  "#166a8f",
  "#00a950",
  "#58595b",
  "#8549ba",
  "#1941A5", //Dark Blue
  "#AFD8F8",
  "#F6BD0F",
  "#8BBA00",
  "#A66EDD",
  "#F984A1",
  "#CCCC00", //Chrome Yellow+Green
  "#999999", //Grey
  "#0099CC", //Blue Shade
  "#FF0000", //Bright Red
  "#006F00", //Dark Green
  "#0099FF", //Blue (Light)
  "#FF66CC", //Dark Pink
  "#669966", //Dirty green
  "#7C7CB4", //Violet shade of blue
  "#FF9933", //Orange
  "#9900FF", //Violet
  "#99FFCC", //Blue+Green Light
  "#CCCCFF", //Light violet
  "#669900", //Shade of green
];

const getColor = (index) => {
  return COLORS[index % COLORS.length];
};

// axios refresh logic
let isTokenRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((failed) => {
    if (error) {
      failed.reject(error);
    } else {
      failed.resolve(token);
    }
  });
  failedQueue = [];
};

const REFRESH_URL = "/accounts/refresh";

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}`,
});

axios.interceptors.request.use(
  async (config) => {
    const accessToken = await auth.getToken();

    config.headers = {
      ...config.headers,
      ...(!config.forGuest
        ? {
            ...(accessToken ? { Authorization: accessToken } : {}),
          }
        : {}),
    };
    config.withCredentials = true;

    return config;
  },
  (error) => {
    return Promise.resolve(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    // 토큰 만료시
    if (status === 401) {
      if (isTokenRefreshing && !originalRequest._retry) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            //console.log({ originalRequest, token });
            originalRequest.headers.Authorization = token;
            originalRequest._retry = true;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isTokenRefreshing = true;

      //const refreshToken = await auth.getRefreshToken();

      return new Promise(function (resolve, reject) {
        axios
          .post(REFRESH_URL)
          .then(async (response) => {
            const newAccessToken = response.data.jwtToken;
            //console.log({ newAccessToken });

            // let rememberMe = false;
            // if (!!localStorage.getItem(consts.TOKEN_KEY)) rememberMe = true;
            // await auth.setToken({ token: newAccessToken, rememberMe });
            //if(auth.isRemember())
            await auth.setToken({
              token: newAccessToken,
              rememberMe: auth.isRemember(),
            });

            originalRequest.headers.Authorization = newAccessToken;

            processQueue(null, newAccessToken);
            resolve(axios(originalRequest));
          })
          .catch(async (error) => {
            //await auth.logout();
            await signout();
            processQueue(error, null);
            reject(error);
          })
          .finally(() => {
            isTokenRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

async function signout() {
  try {
    await axios.post("/accounts/signout", { replace: true });
    await auth.clearToken();
    await stationUtil.clearStationId();
  } catch (e) {
    console.log({ e });
  }
}

const getFirstLetter = (value) => {
  const firstLetter = value[0].toUpperCase();
  const uniCode = firstLetter.charCodeAt() - 44032;
  if (/\d/.test(firstLetter)) {
    return "0-9";
  } else if (uniCode > -1 && uniCode < 11172) {
    return consts.HANGUL_CHO[Math.floor(uniCode / 588)];
  }
  return firstLetter;
};

const formatWatt = (a, b) => {
  const e = ["kW", "MW", "GW", "TW", "PW"];
  const d = b || 2;

  if (1 >= a) return { v: a.toFixed(d), f: e[0] };
  const c = 1000,
    f = Math.floor(Math.log(a) / Math.log(c));
  return {
    v: String(parseFloat((a / Math.pow(c, f)).toFixed(d))).replace(
      regex.CURRENCY,
      ","
    ),
    f: e[f],
  };
};

// 배열 분할하기
const splitIntoChunk = (arr, chunk) => {
  // 빈 배열 생성
  const result = [];

  for (let index = 0; index < arr.length; index += chunk) {
    let tempArray = arr.slice(index, index + chunk);
    result.push(tempArray);
  }
  return result;
};

// 파일 이름관련 헬퍼
const extractFileName = (filename) => {
  /*
   * lastIndexOf('.')
   * 뒤에서부터 .의 위치를 찾기 위한 함수
   * 검색 문자열의 위치를 반환한다.
   * 파일 이름에 .이 포함된 경우가 있기 때문에 lastIndexOf() 사용
   */

  let _lastDot = filename.lastIndexOf(".");

  // 파일명만 추출
  let _fileName = filename.substring(0, _lastDot).toLowerCase();
  return _fileName;
};

const extractFileExtension = (filename) => {
  let _fileLen = filename.length;

  /*
   * lastIndexOf('.')
   * 뒤에서부터 .의 위치를 찾기 위한 함수
   * 검색 문자열의 위치를 반환한다.
   * 파일 이름에 .이 포함된 경우가 있기 때문에 lastIndexOf() 사용
   */

  let _lastDot = filename.lastIndexOf(".");

  // 확장자 명만 추출한 후 소문자로 변경
  let _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
  return _fileExt;
};

const extractFilenameExtension = (filename) => {
  return {
    name: extractFileName(filename),
    extension: extractFileExtension(filename),
  };
};

// 글자수 줄이기
const shortenWords = (str, defaultLen = 10, defaultTail = "(...)") => {
  let result = str;
  if (str.length > defaultLen) {
    result = str.substr(0, defaultLen) + defaultTail;
  }
  return result;
};

const sum = (array) => {
  return array.reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  }, 0);
};

const average = (array) => {
  return (
    array.reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    }, 0) / array.length
  );
};

export {
  consts,
  openSidebar,
  closeSidebar,
  toggleSidebar,
  regex,
  numberFixedFormat,
  durationMinute,
  isEmpty,
  axios,
  getFirstLetter,
  getColor,
  signout,
  formatWatt,
  splitIntoChunk,
  extractFileName,
  extractFileExtension,
  extractFilenameExtension,
  shortenWords,
  sum,
  average,
};
