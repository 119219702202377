import React, { useState, useCallback, useEffect } from "react";

import {
  Grid,
  Checkbox,
  //Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider,
  Button,
} from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
//import MDButton from "../../components/MDButton";
import { axios } from "../../common";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import SearchField from "../../components/SearchField";

const _ = ({
  cusNo,
  handleLinkStation,
  handleNewStation,
  handleDelStation,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const { control, watch } = useForm({
    defaultValues: {
      leftKeyword: "",
      rightKeyword: "",
    },
  });

  const [leftValues, setLeftValues] = useState([]);
  const [rightValues, setRightValues] = useState([]);
  const [checked, setChecked] = useState([]);

  const loadMapp = useCallback(async () => {
    try {
      const { status, data } = await axios.get("/certify/customer/mapp", {
        params: { cusNo: cusNo },
      });

      if (status === 200) {
        // 연결가능 발전소
        setLeftValues(data.data.filter((f) => !f.isConnected));
        // 연결중인 발전소
        setRightValues(data.data.filter((f) => f.isConnected));
        handleLinkStation(data.data.filter((f) => f.isConnected));
      }
    } catch (e) {}
  }, [cusNo, handleLinkStation]);

  useEffect(() => {
    loadMapp();
  }, [loadMapp]);

  const leftChecked = intersection(checked, leftValues);
  const rightChecked = intersection(checked, rightValues);

  const handleToggle = useCallback(
    (value) => {
      const currentIndex = checked.indexOf(value);

      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
        setChecked(newChecked);
      } else {
        setChecked(
          newChecked.map((check, index) => ({
            ...check,
            isDelete: index === currentIndex,
          }))
        );
      }
    },
    [checked]
  );

  const numberOfChecked = useCallback(
    (items) => {
      return intersection(checked, items).length;
    },
    [checked]
  );

  const handleToggleAll = useCallback(
    (items) => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    },
    [checked, numberOfChecked]
  );

  const customList = useCallback(
    (items, keyword) => {
      const f = keyword
        ? items.filter((f) =>
            f.stationName.toLowerCase().includes(keyword.toLowerCase())
          )
        : items;

      return (
        <List
          sx={{
            width: "auto",
            height: isMd ? 500 : 200,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          //role="list"
        >
          {f.map((item) => {
            const labelId = `transfer-list-all-item-${item.stationNo}-label`;
            return (
              <ListItem
                key={item.stationNo}
                //role="listitem"
                //button
                onClick={() => handleToggle(item)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={item.id}
                  primary={`${item.stationName}(${item.stationNo})`}
                />
              </ListItem>
            );
          })}
        </List>
      );
    },
    [checked, handleToggle, isMd]
  );

  // 왼쪽에서 오른쪽으로
  const handleToRight = useCallback(() => {
    handleNewStation(leftChecked);
    setRightValues((prev) => [...prev, ...leftChecked]);
    setLeftValues((prev) => not(prev, leftChecked));
    setChecked((prev) => not(prev, leftChecked));
  }, [handleNewStation, leftChecked]);

  // 오른쪽에서 왼쪽으로(삭제)
  const handleToLeft = useCallback(() => {
    handleDelStation(rightChecked);
    setLeftValues((prev) => [...prev, ...rightChecked]);
    setRightValues((prev) => not(prev, rightChecked));
    setChecked((prev) => not(prev, rightChecked));
  }, [handleDelStation, rightChecked]);

  return (
    <Grid container justifyContent="center" alignItems="center" mt={2}>
      <Grid item xs={12} sm={12} md={5} lg={5.5}>
        <MDBox sx={{ boxShadow: 2, borderRadius: 3 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox
                sx={{
                  display: "flex",
                  px: 0,
                  py: 1,
                  pt: 3,
                  alignItems: "center",
                }}
              >
                <Checkbox
                  onClick={() => handleToggleAll(leftValues)}
                  checked={
                    numberOfChecked(leftValues) === leftValues.length &&
                    leftValues.length !== 0
                  }
                  indeterminate={
                    numberOfChecked(leftValues) !== leftValues.length &&
                    numberOfChecked(leftValues) !== 0
                  }
                  disabled={leftValues.length === 0}
                  inputProps={{
                    "aria-label": "all items selected",
                  }}
                />
                <MDBox>
                  <MDTypography variant="h4" component="div">
                    연결가능 발전소
                  </MDTypography>
                  <MDTypography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {`${numberOfChecked(leftValues)}/${leftValues.length} 선택`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ px: 2, py: 1, pt: 3 }}
            >
              <Controller
                control={control}
                name="leftKeyword"
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <SearchField
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider />
          {customList(leftValues, watch("leftKeyword"))}
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={1}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5, color: "#328ced" }}
            variant="outlined"
            size="small"
            //onClick={handleCheckedRight}
            onClick={handleToRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
            color="secondary"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5, color: "#328ced" }}
            variant="outlined"
            size="small"
            onClick={handleToLeft}
            //onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
            color="secondary"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={5} lg={5.5}>
        <MDBox sx={{ boxShadow: 2, borderRadius: 3 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox
                sx={{
                  display: "flex",
                  px: 0,
                  py: 1,
                  pt: 3,
                  alignItems: "center",
                }}
              >
                <Checkbox
                  onClick={() => handleToggleAll(rightValues)}
                  checked={
                    numberOfChecked(rightValues) === rightValues.length &&
                    rightValues.length !== 0
                  }
                  indeterminate={
                    numberOfChecked(rightValues) !== rightValues.length &&
                    numberOfChecked(rightValues) !== 0
                  }
                  disabled={rightValues.length === 0}
                  inputProps={{
                    "aria-label": "all items selected",
                  }}
                />
                <MDBox>
                  <MDTypography variant="h4" component="div">
                    연결된 발전소
                  </MDTypography>
                  <MDTypography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {`${numberOfChecked(rightValues)}/${
                      rightValues.length
                    } 선택`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ px: 2, py: 1, pt: 3 }}
            >
              <Controller
                control={control}
                name="rightKeyword"
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <SearchField
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider />
          {customList(rightValues, watch("rightKeyword"))}
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default _;

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
