import React, { useEffect, useState, forwardRef, useCallback } from "react";
import AutocompleteField from "../../components/AutocompleteField";
import { isEmpty, axios } from "../../common";

const _ = forwardRef(({ onChange, value, cusId }, ref) => {
  const [items, setItems] = useState([]);

  const load = useCallback(async (paramId) => {
    try {
      const { status, data } = await axios.get("/station/my", {
        params: { cusId: paramId },
      });

      if (status === 200) {
        setItems(
          data.data.map((item) => ({
            ...item,
          }))
        );
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (cusId) {
      load(cusId);
    }
  }, [cusId, load]);

  return (
    !isEmpty(items) && (
      <AutocompleteField
        value={value}
        optionValues={items}
        placeholder="발전소를 선택하세요."
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        ref={ref}
      />
    )
  );
});

export default _;
