import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";

import { Card, useMediaQuery, Grid, TableRow, TableCell } from "@mui/material";

//import { debounce } from "loadsh";

//import { useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { useTheme } from "@mui/material/styles";

import DashboardLayout from "./LayoutContainer/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
//import RefreshIcon from "@mui/icons-material/Refresh";

import DataTable from "../components/DataTable";
import { axios, numberFixedFormat, getColor, sum } from "../common";
import dayjs from "dayjs";
import SelectField from "../components/SelectField";
import DatePickerField from "../components/DatePickerField";
import LineChart from "../components/Charts/LineChart";
import Loading from "../components/Loading";
import { useMaterialUIController } from "../context";

import { useTranslation } from "react-i18next";
import MDButton from "../components/MDButton";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

import { useReactToPrint } from "react-to-print";

const HEAD_CELLS = [
  {
    id: "keyValue",
    label: "시간",
    scale: "Hour",
    width: "80px",
    //sx: { fontSize: "24px" },
    rowSpan: (isMd) => 2,
    format: (value) => dayjs(value).format("HH") + ":00",
    isMd: true,
  },
  {
    id: "input",
    label: "입력측",
    colSpan: (isMd) => (isMd ? 3 : 1),
    align: "center",
    //sx: { fontSize: "24px" },
    nested: [
      {
        id: "solarVolt",
        label: "전압",
        align: "center",
        //sx: { fontSize: "24px" },
        scale: "V",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
      {
        id: "solarCurrent",
        label: "전류",
        align: "center",
        //sx: { fontSize: "24px" },
        scale: "A",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },

      {
        id: "solarPower",
        label: "전력",
        align: "center",
        //sx: { fontSize: "24px" },
        scale: "kW",
        format: (value) => numberFixedFormat(value, 0),
        isMd: true,
      },
    ],
    isMd: true,
  },
  {
    id: "output",
    label: "출력측",
    colSpan: (isMd) => (isMd ? 3 : 1),
    align: "center",
    //sx: { fontSize: "24px" },
    nested: [
      {
        id: "lineVoltRs",
        label: "전압",
        align: "center",
        //sx: { fontSize: "24px" },
        scale: "V",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },
      {
        id: "lineCurrR",
        label: "전류",
        align: "center",
        //sx: { fontSize: "24px" },
        scale: "A",
        format: (value) => numberFixedFormat(value, 0),
        isMd: false,
      },

      {
        id: "invPower",
        label: "전력",
        align: "center",
        //sx: { fontSize: "24px" },
        scale: "kW",
        format: (value) => numberFixedFormat(value, 0),
        isMd: true,
      },
    ],
    isMd: true,
  },

  {
    id: "invPf",
    label: "역률",
    align: "center",
    //sx: { fontSize: "24px" },
    rowSpan: (isMd) => 2,
    scale: "%",
    format: (value) => numberFixedFormat(value, 1),
    isMd: false,
  },
  {
    id: "lineFrequency",
    label: "주파수",
    align: "center",
    //sx: { fontSize: "24px" },
    rowSpan: (isMd) => 2,
    scale: "Hz",
    format: (value) => numberFixedFormat(value, 1),
    isMd: false,
  },
  {
    id: "invMinute",
    label: "발전량",
    align: "center",
    //sx: { fontSize: "24px" },
    rowSpan: (isMd) => 2,
    scale: "kWh",
    format: (value) => numberFixedFormat(value, 0),
    isMd: true,
  },
];

const _ = () => {
  const { station } = useAuth();

  const { t } = useTranslation();

  const [controller] = useMaterialUIController();
  const { lang } = controller;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isLoading, setIsLoading] = useState(true);
  const [selectDate, setSelectDate] = useState(dayjs());
  const [selectDevice, setSelectDevice] = useState("all");
  const [items, setItems] = useState([]);
  const [data, setData] = useState();
  const [chartItem, setChartItem] = useState();

  const IdLabel = t("labels.orderNumber");

  const renderChart = useCallback(
    (chartData, selDev) => {
      if (!chartData) {
        return;
      }

      let charts = [];

      if (selDev === "all") {
        station?.devices.forEach((item, index) => {
          var t = chartData
            .filter((f) => f.invOrder === item.label)
            .map((s) => ({
              x: s.keyValue,
              y: s.invMinute,
            }));

          let color = getColor(index);

          charts.push({
            label: `${IdLabel} ${item.label}`,
            data: t,
            borderColor: color,
            borderWidth: 2,
            lineTension: 0.2,
            backgroundColor: color,
          });
        });
      } else {
        const invOrder = station?.devices.find(
          (f) => f.value === selDev
        )?.label;

        var t = chartData
          .filter((f) => f.invOrder === invOrder)
          .map((s) => ({
            x: s.keyValue,
            y: s.invMinute,
          }));

        let color = getColor(0);

        charts.push({
          label: `${IdLabel} ${invOrder}`,
          data: t,
          borderColor: color,
          borderWidth: 2,
          lineTension: 0.2,
          backgroundColor: color,
        });
      }

      setChartItem({ datasets: charts });
    },
    [IdLabel, station?.devices]
  );

  const loadReport = useCallback(async (stationId, toDate) => {
    try {
      const { status, data } = await axios.get("station/daily", {
        params: { stationId, toDate: dayjs(toDate).format("YYYY-MM-DD") },
      });

      if (status === 200) {
        //setData({ items: data.data });

        const resp = data.data;
        setItems(resp);

        //renderChart(resp);

        setIsLoading(false);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (station?.stationNo && selectDate) {
      setIsLoading(true);
      loadReport(station.stationNo, selectDate);
    }
  }, [loadReport, station, selectDate]);

  useEffect(() => {
    if (items && selectDevice) {
      renderChart(items, selectDevice);
    }
  }, [items, renderChart, selectDevice]);

  useEffect(() => {
    if (items) {
      const invOrder =
        selectDevice === "all"
          ? 0
          : station?.devices.find((f) => f.value === selectDevice)?.label;
      const fn = items.filter((f) => f.invOrder === invOrder);

      setData({ items: fn });
    }
  }, [items, selectDevice, station]);

  const refPrint = useRef();

  const reportTitle = t("labels.printDaily");
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
    documentTitle: `${station?.stationName} ${reportTitle}`,
    pageStyle: `
    @media print {
      .no-print {
        display: none !important;
      }
      @page {
        size: a4;
        margin: 0.in;
      }

      body {
        width: 100%;
        height: 100%;
        zoom: 35%;
      }

      table, th, td {
        font-size: 24px !important;
      }

      p {
        font-size: 18px !important;
      }

      canvas {
        zoom: 110%;
      }
    }
    `,
  });

  //console.log({ selectDevice });
  const [reportSum, setReportSum] = useState(0);

  useEffect(() => {
    if (data?.items) {
      const selectId =
        selectDevice === "all"
          ? 0
          : station?.devices.find((f) => f.value === selectDevice)?.label;

      const ss = data?.items
        .filter((f) => f.invOrder === selectId)
        .map((item) => item.invMinute);

      setReportSum(sum(ss));
    }
  }, [data, selectDevice, station]);

  // const summaryId =
  //   selectDevice === "all"
  //     ? 0
  //     : station?.devices.find((f) => f.value === selectDevice)?.label;

  // const suam = data?.items
  //   .filter((f) => f.invOrder === summaryId)
  //   .map((item) => item.invMinute);

  // console.log(suam && sum(suam));

  return (
    <DashboardLayout>
      {!station?.stationNo ? (
        <MDBox py={3} sx={{ textAlign: "center" }}>
          <MDTypography variant="h6" color="info" textGradient>
            {t("emptyMessage.emptyStation")}
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox py={3}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDTypography variant="h6" gutterBottom>
                  {station?.stationName || ""}
                </MDTypography>

                <MDBox>
                  <MDButton
                    variant="outlined"
                    size="small"
                    color={"info"}
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  >
                    {t("labels.buttonPrint")}
                  </MDButton>
                </MDBox>
                {/* <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton> */}
              </MDBox>
              {isLoading ? (
                <Loading loadingMessage={t("labels.generationDaily")} />
              ) : (
                <MDBox ref={refPrint}>
                  {chartItem && (
                    <MDBox
                      p={3}
                      sx={{
                        "& canvas": { minHeight: isMd ? "45vh" : "35vh" },
                      }}
                    >
                      <LineChart
                        configOptions={{
                          xTitle: t("labels.xTitleDaily"),
                          yTitle: "kWh",
                          xOtherOptions: {
                            type: "time",
                            time: {
                              unit: "hour",
                              displayFormats: {
                                hour: `HH`,
                              },
                            },
                            min: `${selectDate.format("YYYY-MM-DD")} 05:00:00`,
                            max: `${selectDate.format("YYYY-MM-DD")} 21:00:00`,
                            ticks: {
                              autoSkip: true,
                              //beginAtZero: true,
                              //stepSize: 1
                              //maxRotation: 90
                            },
                            // ticks: {
                            //   beginAtZero: true,
                            //   stepSize: 2,
                            //   fontColor: "rgba(251, 203, 9, 1)",
                            //   fontSize: 14,
                            // },
                          },
                          yOtherOptions: {
                            min: 0,
                            ticks: {
                              //beginAtZero: true,
                              //min: 0,
                            },
                          },
                        }}
                        title={
                          lang === "ko"
                            ? `${dayjs(selectDate).format("YYYY-MM-DD")} ${t(
                                "labels.reportTitle"
                              )}`
                            : `${t("labels.reportTitle")} ${dayjs(
                                selectDate
                              ).format("YYYY/MM/DD")}`
                        }
                        data={chartItem}
                      />
                    </MDBox>
                  )}

                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                    className="no-print"
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <DatePickerField
                          value={selectDate}
                          onChange={(e) => setSelectDate(dayjs(e))}
                          locale={lang}
                        />
                      </Grid>
                      {isMd && <Grid item md={6} lg={8} />}
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <MDBox display="flex" sx={{ alignItems: "center" }}>
                          {/* <MDTypography
                    variant="h6"
                    sx={{ width: theme.typography.pxToRem(60) }}
                  >
                    국번 :
                  </MDTypography> */}
                          <SelectField
                            value={selectDevice}
                            onChange={(e) => {
                              setSelectDevice(e.target.value);
                            }}
                            valueOptions={[
                              { value: "all", label: t("labels.all") },
                              ...(station?.devices || []),
                            ]}
                            isClearButton={false}
                            placeholder="국번"
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>

                  <DataTable
                    headCells={HEAD_CELLS}
                    data={data}
                    emptyMessage={t("labels.emptyReport")}
                    orderAlign="asc"
                    //isLoading={}
                    footer={
                      <TableRow>
                        <TableCell colSpan={isMd ? 10 : 3}>
                          <MDBox
                            sx={{
                              display: "flex",
                              //justifyContent: "space-between",
                              justifyContent: "flex-end",
                              marginRight: "10px",
                              "& h6 + h6": {
                                marginLeft: "20px",
                              },
                            }}
                          >
                            <MDTypography
                              variant="subtitle1"
                              sx={{ fontSize: "22px", fontWeight: "bold" }}
                            >
                              발전량 합계
                            </MDTypography>
                            <MDTypography
                              variant="subtitle1"
                              sx={{ fontSize: "22px", fontWeight: "bold" }}
                            >
                              {numberFixedFormat(reportSum, 0)} kWh
                            </MDTypography>
                          </MDBox>
                        </TableCell>
                      </TableRow>
                    }
                  />
                  {/* <MDBox>
                    <MDTypography>발전량 합계</MDTypography>
                  </MDBox> */}
                </MDBox>
              )}
            </Card>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default _;
