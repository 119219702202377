/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import routes from "../../routes";
import { useTranslation } from "react-i18next";

function Breadcrumbs({ icon, title, route, light }) {
  const currRoutes = route.slice(0, -1);
  const { t } = useTranslation();

  //console.log({ title, route, currRoutes });

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        {/* <Link to="/"> */}
        <MDTypography
          component="span"
          variant="body2"
          color={light ? "white" : "dark"}
          opacity={light ? 0.8 : 0.5}
          sx={{ lineHeight: 0 }}
        >
          <HomeIcon />
        </MDTypography>
        {/* </Link> */}
        {currRoutes.map((el) => (
          // <Link to={`/${el}`} key={el}>
          <MDTypography
            key={el}
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            {/* {routes.find((f) => f === el)?.name} */}
            {t(`breadcrumbs.${el}`)}
          </MDTypography>
          // </Link>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {/* {title.replace("-", " ")} */}
          {routes.find((f) => f.key === title)?.breadcrumb}
          {/* {t(`breadcrumb.${title}`)} */}
        </MDTypography>
      </MuiBreadcrumbs>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {/* {routes.find((f) => f.key === title)?.name} */}
        {/* {routes.find((f) => f.key === title)?.name} */}
        {/* {title.replace("-", " ")} */}
        {t(`breadcrumbs.${title}`)}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

export default Breadcrumbs;
