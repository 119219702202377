import SolarPowerIcon from "@mui/icons-material/SolarPower";
import SpeedIcon from "@mui/icons-material/Speed";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ProtectedRoute from "./components/ProtectedRoute";

import Signin from "./screens/authentication/Signin";
import Announce from "./screens/Announce";
import StationDashboard from "./screens/Station-Dashboard";
import StationEnergy from "./screens/Station-Energy";
import StationInverter from "./screens/Station-Inverter";
import StationAlarm from "./screens/Station-Alarm";
import StationAlarmHistory from "./screens/Station-AlarmHistory";

import ReportDaily from "./screens/Report-Daily";
import ReportMonthly from "./screens/Report-Monthly";
import ReportYearly from "./screens/Report-Yearly";

import StationList from "./screens/certify/StationList";
import StationCreate from "./screens/certify/StationCreate";
import InverterList from "./screens/certify/InverterList";
import InverterCreate from "./screens/certify/InverterCrate";
import CustomerList from "./screens/certify/CustomerList";
import CustomerCreate from "./screens/certify/CustomerCreate";

import AnnounceList from "./screens/certify/AnnounceList";
import AnnounceCreate from "./screens/certify/AnnounceCreate";

const routes = [
  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "로그인",
    key: "signin",
    icon: <SolarPowerIcon fontSize="small" />,
    route: "/signin",
    component: <Signin />,
    root: false,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "공지 사항",
    key: "announce",
    icon: <AssignmentOutlinedIcon fontSize="small" />,
    route: "/announce",
    component: (
      <ProtectedRoute>
        <Announce />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "관제 센터",
    key: "dashboard",
    icon: <DashboardIcon fontSize="small" />,
    route: "/dashboard",
    component: (
      <ProtectedRoute>
        <StationDashboard />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "발전소 현황",
    key: "station",
    icon: <SolarPowerIcon fontSize="small" />,
    route: "/station",
    component: (
      <ProtectedRoute>
        <StationEnergy />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "인버터 현황",
    key: "inverter",
    icon: <SpeedIcon fontSize="small" />,
    route: "/inverter",
    component: (
      <ProtectedRoute>
        <StationInverter />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "실시간 경보",
    key: "alarm",
    icon: <WarningAmberIcon fontSize="small" />,
    route: "/alarm",
    component: (
      <ProtectedRoute>
        <StationAlarm />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "경보 이력",
    key: "history",
    icon: <AccessAlarmIcon fontSize="small" />,
    route: "/history",
    component: (
      <ProtectedRoute>
        <StationAlarmHistory />
      </ProtectedRoute>
    ),
    root: true,
  },

  { type: "title", title: "보고서", key: "report_title", root: true },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "일보",
    key: "daily",
    icon: <AssessmentIcon fontSize="small" />,
    route: "/daily",
    component: (
      <ProtectedRoute>
        <ReportDaily />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "월보",
    key: "monthly",
    icon: <AssessmentIcon fontSize="small" />,
    route: "/monthly",
    component: (
      <ProtectedRoute>
        <ReportMonthly />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "Renewable Energy",
    name: "연보",
    key: "yearly",
    icon: <AssessmentIcon fontSize="small" />,
    route: "/yearly",
    component: (
      <ProtectedRoute>
        <ReportYearly />
      </ProtectedRoute>
    ),
    root: true,
  },

  { type: "divider", key: "certify_divider", root: true },

  { type: "title", title: "관리자", key: "certify_title", root: true },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "발전소 관리",
    key: "certify/stations",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "/certify/stations",
    component: (
      <ProtectedRoute authority>
        <StationList />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "발전소 관리",
    key: "certify/stations/create",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "/certify/stations/create",
    component: (
      <ProtectedRoute authority>
        <StationCreate />
      </ProtectedRoute>
    ),
    root: false,
  },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "인버터관리",
    key: "certify/inverters",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "/certify/inverters",
    component: (
      <ProtectedRoute authority>
        <InverterList />
      </ProtectedRoute>
    ),

    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "인버터 관리",
    key: "certify/inverters/create",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "/certify/inverters/create",
    component: (
      <ProtectedRoute authority>
        <InverterCreate />
      </ProtectedRoute>
    ),
    root: false,
  },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "고객관리",
    key: "certify/customers",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "certify/customers",
    component: (
      <ProtectedRoute authority>
        <CustomerList />
      </ProtectedRoute>
    ),
    root: true,
  },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "고객관리",
    key: "certify/customers/create",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "certify/customers/create",
    component: (
      <ProtectedRoute authority>
        <CustomerCreate />
      </ProtectedRoute>
    ),
    root: false,
  },

  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "공지사항",
    key: "certify/announces",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "certify/announces",
    component: (
      <ProtectedRoute authority>
        <AnnounceList />
      </ProtectedRoute>
    ),
    root: true,
  },
  {
    type: "collapse",
    breadcrumb: "관리자",
    name: "공지사항",
    key: "certify/announces/create",
    icon: <SettingsSuggestIcon fontSize="small" />,
    route: "certify/announces/create",
    component: (
      <ProtectedRoute authority>
        <AnnounceCreate />
      </ProtectedRoute>
    ),
    root: false,
  },
];

export default routes;
