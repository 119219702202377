// @mui material components
import { useMemo, useRef, useCallback } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Divider } from "@mui/material";
import TextField from "@mui/material/TextField";

import { debounce } from "loadsh";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

import BasicLayout from "./components/BasicLayout";
import { useForm, Controller } from "react-hook-form";

import { auth } from "../../utils/auth";
import { stationUtil } from "../../utils/station";
import { axios } from "../../common";
import { useAuth } from "../../AuthProvider";

import { useLocation, useNavigate } from "react-router-dom";

import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";

const _ = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setUser, setStation } = useAuth();

  const loadStationInfo = useCallback(
    async (stationId) => {
      try {
        const { status, data } = await axios.get("station/info", {
          params: { stationId },
        });

        if (status === 200) {
          setStation(data.data);
        }
      } catch (e) {}
    },
    [setStation]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      password: "",
      rememberMe: true,
    },
  });

  const onSubmit = useMemo(
    () =>
      debounce(async (form) => {
        console.log({ form });
        try {
          const { status, data } = await axios.post("/accounts/authenticate", {
            id: form.id,
            password: form.password,
          });

          if (status === 200) {
            setUser(data);
            auth.setToken({
              token: data.jwtToken,
              rememberMe: form.rememberMe,
            });

            if (data.stationId) {
              stationUtil.setStationId(data.stationId);
              await loadStationInfo(data.stationId);
            }

            setTimeout(() => {
              const { from } = location.state || {};
              const redirect =
                data?.role === "Authority" ? "/certify/stations" : "/station";
              navigate(from || redirect, { replace: true });
            }, 1);
          }
        } catch (e) {
          console.log({ e });
          alert("가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.");
        }
      }, 300),
    [location, navigate, setUser, loadStationInfo]
  );

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
            HEX POWER SYSTEM
            <br />
            오신걸 환영합니다.
          </MDTypography>
          <Divider />
          <MDTypography variant="h6" color="white">
            모니터링 로그인
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <Controller
                control={control}
                name="id"
                rules={{
                  required: "[필수] 아이디를 입력하세요.",
                }}
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <MDInput
                    placeholder="아이디"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                  />
                )}
              />
              {errors?.cusId?.message && (
                <MDTypography
                  sx={{ fontSize: "10pt", marginLeft: "2px", marginTop: "2px" }}
                  color="error"
                >
                  {errors.cusId.message}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <Controller
                control={control}
                rules={{
                  required: "[필수] 패스워드를 입력하세요.",
                }}
                name="password"
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <MDInput
                    type="password"
                    placeholder="패스워드"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        e.stopPropagation(); // 필수
                        handleSubmit(onsubmit);
                      }
                    }}
                  />
                )}
              />
              {errors?.cusPw?.message && (
                <MDTypography
                  sx={{ fontSize: "10pt", marginLeft: "2px", marginTop: "2px" }}
                  color="error"
                >
                  {errors.cusPw.message}
                </MDTypography>
              )}
            </MDBox>
            <Controller
              control={control}
              name="rememberMe"
              render={({ field: { value, onChange } }) => (
                <MDBox
                  display="flex"
                  alignItems="center"
                  ml={-1}
                  // sx={{
                  //   color: "#f50057",
                  //   "&.Mui-disabled": {
                  //     color: "#e886a9",
                  //   },
                  //   "&.Mui-checked": {
                  //     color: "#95cc97",
                  //   },
                  //   "&.Mui-checked + .MuiSwitch-track": {
                  //     backgroundColor: "#4CAF50",
                  //   },
                  // }}
                >
                  <Switch
                    checked={value}
                    onChange={onChange}
                    sx={{
                      "&.MuiSwitch-switchBase.Mui-checked": {
                        color: "green",
                      },
                      // "&.MuiSwitch-root .MuiSwitch-switchBase": {
                      //   color: "red",
                      // },

                      // "&.MuiSwitch-root .Mui-checked": {
                      //   color: "green",
                      // },
                    }}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={onChange}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;자동로그인
                  </MDTypography>
                </MDBox>
              )}
            />

            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                로그인
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default _;
