import React, { useCallback, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useAuth } from "../AuthProvider";
import { auth } from "../utils/auth";
import { axios, signout } from "../common";
import useConstructor from "../hooks/useConstructor";

const ProtectedRoute = ({ children, redirectPath = "/signin", authority }) => {
  const [isPending, setIsPending] = useState(true);
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const loadAccount = useCallback(async () => {
    try {
      const { status, data } = await axios.get("/accounts");

      if (status === 200) {
        if (authority) {
          if (data.role !== "Authority" && data.role !== "Operator") {
            alert("잘못된 접근 입니다.");
            await signout();
            navigate("/signin", { replace: true });

            return;
          }
        }

        setUser(data);

        setIsPending(false);
      }
    } catch (e) {
      setUser(null);
      setIsPending(false);
    }
  }, [authority, setUser, navigate]);

  useConstructor(() => {
    if (auth.isAuthenticated()) {
      loadAccount();
    } else {
      setIsPending(false);
    }
  });

  return isPending ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress color="info" />
    </Box>
  ) : user?.id ? (
    children
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

export default ProtectedRoute;
