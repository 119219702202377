const LANG_KEY = "_my_lang_";

export const langUtil = {
  getLang,
  setLang,
};

async function getLang() {
  return await localStorage.getItem(LANG_KEY);
}

async function setLang(value) {
  await localStorage.setItem(LANG_KEY, value);
}
